import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { DatePick } from '../../types';
import { Store } from "@ngxs/store";
import { ActiveFpsoState } from "../../../statemanagement";

@Component({
  selector: 'co2-performance-drift',
  templateUrl: './performance-drift.component.html',
  styleUrls: ['./performance-drift.component.scss']
})
export class PerformanceDriftComponent implements OnInit{
  dailyPerformanceDriftGraphInputs!: EChartsOption;
  hourlyPerformanceDriftGraphInputs!: EChartsOption;
  @Input() isLoading!: boolean | null;
  @Input() hasLoadingError!: boolean | null;
  @Output() reloadData = new EventEmitter();
  @Input() displayDatePicker?: boolean;
  @Input() startDatePicker!: Date | string;
  @Input() endDatePicker!: Date | string;
  @Input() actualDate!: Date;
  @Input() noAvailableData?: boolean | null;
  @Output() datePick = new EventEmitter<DatePick>();
  @Output() quickSelectionChange = new EventEmitter<any>();

  @Input()
  set performanceDriftGraphInputs(inputs: EChartsOption[]) {
    if (inputs && inputs[0]) {
      this.dailyPerformanceDriftGraphInputs = inputs[0];
    }
    if (inputs && inputs[1]) {
      this.hourlyPerformanceDriftGraphInputs = inputs[1];
    }
  }

  fpso: string = '';
  listOfFpsosWithoutSensor: string[] = ['PAZFLOR'];

  constructor(private store: Store) {}


  ngOnInit() {
    this.fpso = this.store.selectSnapshot(ActiveFpsoState);
  }

  isNoDataAvailable(graph: any): boolean {
    return !graph.series || graph.series.length === 0 || graph.series.flatMap((s: {data: any}) => s.data).length === 0;
  }

  getNonAvailabilityMessage(): string {
    return this.listOfFpsosWithoutSensor.includes(this.fpso) ? `For ${this.fpso}, as there is no sensor for Fuel Gas flowrate for each TurboGenerator separately, it is not possible to trace the perfomance drift of each TurboGenerator.` : ''
  }
}
