import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ClearWarningsData, GetWarningsData } from './actions';
import { Alert, Observations, ObservationsWarnings, PointCouple, Warning } from '../../app/types';
import {
  ALERT_TREND_GRAPH, Warning_TREND_GRAPH
} from 'src/modules/app/containers/general-dashboard/general-dashboard.container.const';
import { tooltipFormatter } from "../../app/utils";
import { cloneObject } from "../../app/utils/object-manipulation/object-manipulation";
import { adjustMinMaxYAxisGraphsForAlerting } from "../../app/utils/graph/graph";

@State<ObservationsWarnings>({
  name: 'warningsData',
  defaults: { warnings: [], totalSize: 0 },
})
@Injectable()
export class WarningssDataState {

  constructor() {
  }

  @Action(GetWarningsData)
  getWarningsData(context: StateContext<ObservationsWarnings>, { payload, resetState }: GetWarningsData): void {
    const updatedPayload: any = payload;

    updatedPayload.warnings.forEach((alert: Warning) => {
      alert.nameWithType = alert.systemType === 'Compression'
        ? `<b>${alert.systemType} ${alert.equipmentType}<br>(${alert.equipmentName})</b>`
        : `<b>${alert.systemType} <b> ${alert.equipmentName}</b>`;

      alert.trendGraph = this.buildAlertGraph(alert.dailyData, alert.originalDailyData);
      alert.hourlyTrendGraph = this.buildAlertGraph(alert.hourlyData, alert.originalHourlyData, true);

      adjustMinMaxYAxisGraphsForAlerting(alert, alert.dailyData, alert.hourlyData);
      adjustMinMaxYAxisGraphsForAlerting(alert, alert.originalDailyData, alert.originalHourlyData);
    });

    context.setState(
      {
        warnings: resetState ? updatedPayload.warnings : context.getState().warnings.concat(updatedPayload.warnings),
        totalSize: updatedPayload.warnings.length
      }
    );
  }

  buildAlertGraph(data: any, dataOriginal: any, isHourly: boolean = false) {
    const graph = cloneObject(Warning_TREND_GRAPH);

    const DEFAULT_LEGEND = {
      backgroundColor: '#F7F9FA',
    };

    const values: any[] = data;
    const originalValues: any[] = dataOriginal;
    graph.tooltip.formatter = tooltipFormatter();
    graph.xAxis.splitNumber = 2;
    graph.legend = {
      ...DEFAULT_LEGEND,
      bottom: 0,
      left: 'center'
    },
      graph.grid = {
        ...graph.grid,
        left: '5px',
        right: '5px'
      }
    graph.xAxis = {
      ...graph.xAxis,
      axisLabel: {
        color: '#666666',
        fontSize: 12,
        margin: 10,
        hideOverlap: true,
        formatter: isHourly ? '{dd}/{MM}' : '{dd}/{MM}/{yyyy}'
      }
    };
    graph.series = [
      {
        name: 'Corrected values',
        type: 'line',
        symbol: 'circle',
        showSymbol: false,
        color: "#FFC694",
        data: values
      }
      ,
      {
        name: 'Real Tag (DAL_6FI12528Cstd)',
        type: 'line',
        symbol: 'circle',
        showSymbol: false,
        color: "#3d61d5",
        data: originalValues
      }
    ];
    return graph;
  }

  @Action(ClearWarningsData)
  clearWarningsData(context: StateContext<ObservationsWarnings>): void {
    context.setState({ warnings: [], totalSize: 0 } as ObservationsWarnings);
  }
}
