import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PendingRequestsService } from '../../services';
import { FAVORITE_ASSET_STORAGE_KEY } from "../../containers";

@Injectable({
  providedIn: 'root'
})
export class RedirectToLoginOrDashboardGuard {

  constructor(private router: Router, private pendingRequestsService: PendingRequestsService) {}

  canActivate(): boolean {
    this.pendingRequestsService.cancelAllPendingRequests();
    const favoriteAsset = this.getFavoriteAsset();
    const route: string[] = (!favoriteAsset.affiliate || !favoriteAsset.fpsos) ? ['login'] : [favoriteAsset.affiliate, favoriteAsset.fpsos, 'general-dashboard'];
    this.router.navigate(route);
    return false;
  }

  private getFavoriteAsset(): any {
    try {
      return JSON.parse(localStorage.getItem(FAVORITE_ASSET_STORAGE_KEY) ?? '{}');
    } catch {
      return {};
    }
  }

}
