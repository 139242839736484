import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  LinearGraph,
  GraphInputs,
} from '../../../../types';
import { EnergyType } from "../../../../types/kpi-overview.interface";

@Component({
  selector: 'co2-energy-performance',
  templateUrl: './energy-performance.component.html',
  styleUrls: ['./energy-performance.component.scss']
})
export class EnergyPerformanceComponent {
  @Input() energyType!: EnergyType | string;
  @Input() energyPerfGraphInputs!: GraphInputs;
  @Input() isLoading!: boolean | null;
  @Input() hasLoadingError!: boolean | null;
  @Output() reloadData = new EventEmitter();

  @Input() powerGraphInputs!: GraphInputs;
  @Input() powerGraphIsLoading!: boolean | null;
  @Input() hasPowerGraphLoadingError!: boolean | null;
  @Output() reloadPowerGraphData = new EventEmitter();

  @Input() asvGraphInputs!: GraphInputs;
  @Input() asvGraphIsLoading!: boolean | null;
  @Input() hasAsvGraphLoadingError!: boolean | null;
  @Output() reloadAsvGraphData = new EventEmitter();

  @Input() polytropicGraphInputs!: GraphInputs;
  @Input() polytropicGraphIsLoading!: boolean | null;
  @Input() hasPolytropicGraphLoadingError!: boolean | null;
  @Output() reloadPolytropicGraphData = new EventEmitter();

  @Output() toggleGraph = new EventEmitter<LinearGraph>();
}
