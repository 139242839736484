import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LinearGraph, GraphInputs } from 'src/modules/app/types';
import { EnergyType } from "../../../../../types/kpi-overview.interface";

@Component({
  selector: 'co2-influential-factors',
  templateUrl: './influential-factors.component.html',
  styleUrls: ['./influential-factors.component.scss']
})
export class InfluentialFactorsComponent {
  @Input() energyType!: EnergyType | string;
  @Input() powerGraphInputs!: GraphInputs | undefined;
  @Input() powerGraphIsLoading!: boolean | null;
  @Input() hasPowerGraphLoadingError!: boolean | null;
  @Output() reloadPowerGraphData = new EventEmitter();

  @Input() asvGraphInputs!: GraphInputs;
  @Input() asvGraphIsLoading!: boolean | null;
  @Input() hasAsvGraphLoadingError!: boolean | null;
  @Output() reloadAsvGraphData = new EventEmitter();

  @Input() polytropicGraphInputs!: GraphInputs;
  @Input() polytropicGraphIsLoading!: boolean | null;
  @Input() hasPolytropicGraphLoadingError!: boolean | null;
  @Output() reloadPolytropicGraphData = new EventEmitter();

  @Output() toggleGraph = new EventEmitter<LinearGraph>();

  COMPRESSOR_GRAPH = LinearGraph;

  onToggleGraph(event: any, graph: LinearGraph): void {
    if (event.target.ariaExpanded === "true" ||
      event.target.attributes["aria-expanded"].value === "true") {
      this.toggleGraph.emit(graph);
    }
  }
}
