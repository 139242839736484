<div class="overview_ghg">
  <div *ngIf="totalCo2YearlyEmission || totalAvoidedCo2 || energyEfficiency" class="overview_ghg__first_block">
    <div class="card">
      <div class="card-title">
        <img alt="DALIA Energy efficiency indicators" class="overview_ghg__img" src="/assets/energy_efficiency.svg"/> {{activeFpso}} Energy efficiency indicators
      </div>
      <div class="card-body">
<!--        <co2-indicator-card class="total_avoidable_emission" *ngIf="totalCo2YearlyEmission" [indicator]="totalCo2YearlyEmission"></co2-indicator-card>-->
        <co2-indicator-card class="energies_efficiency" *ngIf="currentEmissions" [indicator]="currentEmissions"></co2-indicator-card>
        <co2-indicator-card class="energies_efficiency" *ngIf="lowestAchievableEmissions" [indicator]="lowestAchievableEmissions"></co2-indicator-card>
<!--        <co2-indicator-card class="total_avoided" *ngIf="totalAvoidedCo2" [indicator]="totalAvoidedCo2"></co2-indicator-card>-->
        <co2-indicator-card class="energies_efficiency" *ngIf="energyEfficiency" [indicator]="energyEfficiency"></co2-indicator-card>
      </div>
    </div>
  </div>
  <div *ngIf="sankey" class="overview_ghg__second_block">
    <div class="card">
      <div class="card-title">
        <span class="sankey_title">CO2 Emissions & Energy consumption Overview</span> <span class="additional-text">{{sankey ? sankey.date : ''}}</span>
      </div>
      <div class="card-body">
        <co2-sankey [sankey]="sankey"></co2-sankey>
      </div>
    </div>
  </div>
</div>
