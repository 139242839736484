<co2-tab-selector [tabsList]="subTabs" [activeTab]="activeTurbineTab$ | async"
                  (selectTab)="onSelectTab($event)">
</co2-tab-selector>

<div *ngIf="(activeTurbineTab$ | async) === OVERVIEW">
  <co2-overview-kpis *ngIf="globalKPIValues"
                     [equipmentOverview] = "kpiTitle"
                     [globalheaders] ="globalHeaders"
                     [globalRows] ="globalKPIValues"
                     [globalDisplayedHeaders] ="globalDisplayedHeaders"
                     [datePumps]="lastDate"
                     equipment="Turbogen"
                     [detailsHeaders]="detailsHeaders"
                     [detailsRows]="detailsKPIValues"
                     [detailsDisplayedHeaders]="detailsDisplayedHeaders">
  </co2-overview-kpis>

  <co2-cross-plot *ngIf="crossPlotGraph" [crossPlotGraphInput]="crossPlotGraph"
                  [crossPlotTitle]="crossPlotTitle" [isLoading]="turbogenCrossPlotLoader$ | async"
                  [hasLoadingError]="turbogenCrossPlotError$ | async" (reloadData)="sb.loadTurbogenCrossPlot(allEquipmentNames)"
                  (chartClick)="onChartClick($event)" [displayDatePicker]="!fpsoWithSensors" (showAllData)="showAllDataForCrossplot($event)"
                  [startDatePicker]="datePickerProperties.startDatePicker" [endDatePicker]="datePickerProperties.endDatePicker"
                  [equipmentName]="fpso" (datePick)="filterDatePick($event)" [showLegendInfos]="!fpsoWithSensors">
  </co2-cross-plot>

  <co2-performance-drift [performanceDriftGraphInputs]="performanceDriftTurboGraph"
    [isLoading]="combinedPerformanceDriftLoader$ | async" [hasLoadingError]="combinedPerformanceDriftError$ | async"
    (reloadData)="sb.loadTurbogenPerformanceDrift(TURBOGEN_TYPE, getStartDateSince3Years(), allEquipmentNames);
                         sb.loadTurbogenHourlyPerformanceDrift(TURBOGEN_TYPE, allEquipmentNames)" [displayDatePicker]="true"
    [startDatePicker]="this.datePickerProperties.startDatePicker"
    [endDatePicker]="this.datePickerProperties.endDatePicker" (datePick)="filterDatePickDrift($event)">
  </co2-performance-drift>

  <co2-power-data [powerTurbinesGraphInputs]="powerTurbinesGraph"
    [isLoadingPowerTurbines]="turbogenPowerTurbinesLoader$ | async"
    [hasPowerTurbinesLoadingError]="turbogenPowerTurbinesError$ | async" (reloadPowerTurbinesData)="sb.loadTurbogenPowerTurbines(getStartDateSince3Years());
                  sb.loadTurbogenHourlyPowerTurbines(getStartDateSince3Days())" [powerReserveGraph]="powerReserveGraph"
    [isLoadingPowerReserve]="turbogenPowerReserveLoader$ | async"
    [hasPowerReserveLoadingError]="turbogenPowerReserveError$ | async"
    (reloadPowerReserveData)="sb.loadTurbogenPowerReserve()"
                  [fpsoConfigs]="fpsoConfigs"
                  [activeFpsoConfig]="activeFpsoConfig$ | async"
    (fpsoConfigChange)="onFpsoConfigChange($event)">
  </co2-power-data>

  <co2-turbogen-co2-factor-graph *ngIf="turboGeno2FactorGraph" [co2FactorGraphInputs]="co2FactorGraph" [isLoading]="turbogenCo2FactorLoader$ | async"
    [hasLoadingError]="turbogenCo2FactorError$ | async" (reloadData)="sb.loadTurbogenCo2FactorData()">
  </co2-turbogen-co2-factor-graph>
</div>

<div *ngIf="(activeTurbineTab$ | async) !== OVERVIEW">
  <co2-cross-plot *ngIf="crossPlotGraph" [crossPlotGraphInput]="crossPlotGraph"
    [isLoading]="turbogenCrossPlotLoader$ | async" [hasLoadingError]="turbogenCrossPlotError$ | async"
    (reloadData)="sb.loadTurbogenCrossPlot(allEquipmentNames)" (chartClick)="onChartClick($event)" [displayDatePicker]="true"
    [startDatePicker]="datePickerProperties.startDatePicker" [endDatePicker]="datePickerProperties.endDatePicker"
    [equipmentName]="this.sb.getTurbogenEquipmentName()" (showAllData)="showAllDataForCrossplot($event)"
    (datePick)="filterDatePick($event)" [showLegendInfos]="true">
  </co2-cross-plot>

  <co2-performance-drift [performanceDriftGraphInputs]="performanceDriftTurboGraph"
    [isLoading]="combinedPerformanceDriftLoader$ | async" [hasLoadingError]="combinedPerformanceDriftError$ | async"
    (reloadData)="sb.loadTurbogenPerformanceDrift(TURBOGEN_TYPE, getStartDateSince3Years(), allEquipmentNames);
                         sb.loadTurbogenHourlyPerformanceDrift(TURBOGEN_TYPE, allEquipmentNames)" [displayDatePicker]="true"
    [startDatePicker]="this.datePickerProperties.startDatePicker"
    [endDatePicker]="this.datePickerProperties.endDatePicker" (datePick)="filterDatePickDrift($event)">
  </co2-performance-drift>
</div>
