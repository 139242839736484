import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Alert, GraphLegend } from '../../types';

@Component({
  selector: 'co2-table-legend',
  templateUrl: './table-legend.component.html',
  styleUrls: ['./table-legend.component.scss']
})
export class TableLegendComponent {
  @Input() legendList!: GraphLegend[];
  @Input() selectedAlert: Alert | undefined;
  @Output() legendClick = new EventEmitter<GraphLegend>();

  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;

  isDropdownOpen = false;



  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Close the dropdown if the click is outside of it
    if (this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  getCheckedLegendsCount() {
    return this.legendList.filter(legend => legend.checked).length;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onCheckboxChange(legend: GraphLegend, isChecked: boolean) {
    legend.checked = isChecked; // Update the legend's checked state
    this.legendClick.emit(legend); // Emit the updated legend
  }
}
