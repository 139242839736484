import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services';
import { FrontConfigsService } from "../../services/front-configs/front-configs.service";
import { AppSandbox } from "../../app.sandbox";
import { FAVORITE_ASSET_STORAGE_KEY, ROLES_STORAGE_KEY } from "../app";
import { EncryptionService } from "../../services/encryption/encryption.service";
import { Site } from "../../types/site.interface";

@Component({
  selector: 'co2-favorite-asset',
  styleUrls: ['./favorite-asset.container.scss'],
  templateUrl: './favorite-asset.container.html'
})
export class FavoriteAssetContainer implements OnInit {

  sites: Site[] = [];
  roles: string[] = []
  selectedFpso: string = '';

  constructor(private authService: AuthService,
              public frontConfigsService: FrontConfigsService,
              public sb: AppSandbox,
              private encryptionService: EncryptionService) {
  }

  ngOnInit(): void {
    this.roles = this.getRoles();
    this.authService.redirectAuthenticatedUserToHome();
    this.updateSites();
  }

  getRoles(): string[] {
    const rolesData = localStorage.getItem(ROLES_STORAGE_KEY);
    return JSON.parse(this.encryptionService.decryptData(rolesData) ?? '[]');
  }

  updateSites(): void {
    this.frontConfigsService.getFrontConfigs(this.roles).then(data => {
      if (data) {
        this.sites = this.processSitesData(data);
      }
    });
  }

  processSitesData(data: any[]): Site[] {
    return data.reduce((acc, val) => {
      const existingSite = acc.find((site: Site) => site.affiliate === val.affiliate);
      if (existingSite) {
        existingSite.fpsos.push(val.fpso);
      } else {
        acc.push({affiliate: val.affiliate, fpsos: [val.fpso]});
      }
      return acc;
    }, []);
  }

  chooseFpso(): void {
    if (this.sites?.length > 0 && this.selectedFpso) {
      const selectedSite = this.sites.find(asset => asset.fpsos.includes(this.selectedFpso)) ?? {} as Site;
      localStorage.setItem(FAVORITE_ASSET_STORAGE_KEY, JSON.stringify({ affiliate: selectedSite.affiliate, fpsos: this.selectedFpso }));
      this.sb.setActiveFpso(this.selectedFpso);
    }
  }

  listFpsos(): string[] {
    return this.sites.map(asset => asset.fpsos).reduce((acc: string[], val) => acc.concat(val), []);
  }

}
