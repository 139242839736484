import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Alert, DataType, GraphLegend, TableHeader } from 'src/modules/app/types';
import { ObservationTableMetadata } from "../../../../types/observation-table-metadata.interface";

@Component({
  selector: 'co2-alert-table',
  templateUrl: './alert-table.component.html',
  styleUrls: [ './alert-table.component.scss' ]
})
export class AlertTableComponent {
  @Input() alertsTable!: Alert[];
  @Input() warningsTable=[];
  @Input() isLoading!: boolean | null;
  @Input() hasLoadingError!: boolean | null;
  @Input() legendList!: GraphLegend[];
  @Output() closeSidePanel = new EventEmitter<void>();
  @Output() reloadData = new EventEmitter();
  @Output() legendClick = new EventEmitter<GraphLegend>();
  @Output() alertClick = new EventEmitter<Alert>();
  @Input() selectedAlert: Alert | undefined;
  @Input() alertHeaders!: TableHeader[];
  @Input() displayedHeaders!: string[];
  @Input() warningHeaders!: TableHeader[];
  @Input() displayedWarningHeaders!: string[];
  @Input() size: number = 0;
  @Input() alertFilter: string = "";
  @Output() onFilterChange = new EventEmitter<string>();

  activeAlert = true;
  activeWarning = false;
  text = "The warnings correspond to other issues than Equipment performance (example: PI Tag or sensor issues or inconsistent results)."

  headers: TableHeader[] = [
    {
      id: 'name',
      label: 'Name',
      dataType: DataType.TEXT,
    },
    {
      id: 'explanation',
      label: 'Explanation',
      dataType: DataType.TEXT,
    } ];

  metadataList: ObservationTableMetadata[] = [
    { name: 'Trend', explanation: 'Equipment\'s emissions evolution of over the last 3 years' },
    {
      name: 'Avoidable emissions',
      explanation: 'Emissions due to equipment\'s performance deviation, updated daily and ' +
        'that represents the avoidable emissions since the start of observation. ' +
        'View detail on tags and formula by clicking on each row.'
    },
  ]

  displayedModalHeaders: string[] = this.headers.map((header) => header.id);

  onInputFilterChange() {
    if (!this.alertFilter) {
      this.onFilterChange.emit(this.alertFilter);
    }
  }

  onInputFilterChangeKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onFilterChange.emit(this.alertFilter);
    }
  }

  switchToAlerts() {
    this.activeAlert = true;
    this.activeWarning = false;
    this.closeSidePanel.emit();
  }

  switchToWarnings() {
    this.activeAlert = false;
    this.activeWarning = true;
    this.closeSidePanel.emit();
  }
}
