import { KpiOverviewByEquipment } from "../../types/kpi-overview.interface";
import {
  ALERT_DATE_FORMAT,
  formatDateFromTo,
  FRENCH_DATE_FORMAT,
  getLastDate
} from "../date-time-picker/date-time-picker";


export const formatLastDateInKpiEquipments = (kpiOverviews: Array<KpiOverviewByEquipment>): string => {
  const lastDateInKpiByEquipments = getLastDate(kpiOverviews.map((kpi: KpiOverviewByEquipment) => kpi.date)) || "";
  return formatDateFromTo(lastDateInKpiByEquipments, ALERT_DATE_FORMAT, FRENCH_DATE_FORMAT);
}
