import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { EChartsOption } from 'echarts';
import { DatePick } from '../../types';
import { Store } from "@ngxs/store";
import { ActiveAffiliateState, ActiveFpsoState } from "../../../statemanagement";
import { Router } from '@angular/router';
import { EncryptionService } from "../../services/encryption/encryption.service";
import { ROLES_STORAGE_KEY } from "../../containers";
import { BaselineService } from "../../services/baseline/baseline.service";
import { isToday } from "../../utils/date-time/date-time";
import { isEqual } from 'lodash';
import { ToastService } from "../../services/toaster/toaster.service";

@Component({
  selector: 'co2-cross-plot',
  templateUrl: './cross-plot.component.html',
  styleUrls: [ './cross-plot.component.scss' ]
})
export class CrossPlotComponent implements OnInit {
  private _crossPlotGraphInput!: EChartsOption;
  private _crossPlotGraphInput_Old!: EChartsOption; // used only for cancel
  newBaselineUpdatedBy!: string;
  newBaselineUpdatedAt!: Date;
  toInit = false;
  onInitIsCalled = false;

  @Input() _equipmentName!: string;

  @Input() set equipmentName(value: string) {
    if (this._equipmentName !== value) {
      this.firstPoint = undefined;
      this.lastPoint = undefined;
      this._equipmentName = value
    }
  }

  get equipmentName(): string {
    return this._equipmentName;
  }

  @Input() set crossPlotGraphInput(value: EChartsOption) {
    if (this.isCrossplotInputValid(value) && !isEqual(this._crossPlotGraphInput, value)) {
      this._crossPlotGraphInput = value;
      this.initGraph();
    }
  }

  private isCrossplotInputValid(value: any): boolean {
    const condition1: boolean = Array.isArray(value) && !!value?.length;
    const condition2: boolean = !Array.isArray(value) && value;
    return condition1 || condition2;
  }

  private initGraph() {
    this.toInit = !this.toInit && !!this._equipmentName && !!this._crossPlotGraphInput && this.onInitIsCalled;
    if (this.toInit) {
      this.showBaseline();
    }
  }

  get crossPlotGraphInput(): EChartsOption {
    return this._crossPlotGraphInput;
  }

  @Input() isLoading!: boolean | null;
  @Input() hasLoadingError!: boolean | null;

  @Output() reloadData = new EventEmitter();
  @Output() showAllData = new EventEmitter<boolean>();

  @Input() crossPlotTitle?: string = 'Cross-plot';

  @Output() chartClick = new EventEmitter();
  @Input() displayDatePicker?: boolean;
  @Input() startDatePicker!: Date | string;
  @Input() endDatePicker!: Date | string;
  @Input() actualDate!: Date;
  @Output() datePick = new EventEmitter<DatePick>();
  @Output() editToggled = new EventEmitter<boolean>();
  @Input() showLegendInfos = false;

  isAdmin: boolean = false;

  affiliate: string = '';
  fpso: string = '';
  editMode: boolean = false;
  modifiedLineSeries: any;
  firstPoint: any;
  firstPoint_OLD: any;
  lastPoint: any;
  lastPoint_OLD: any;
  isOverview: boolean = false;
  filtersAreDisplayed: boolean = false;

  constructor(private store: Store,
              private cdr: ChangeDetectorRef,
              private route: Router,
              private encryptionService: EncryptionService,
              private baselineService: BaselineService,
              private toastService: ToastService) {
    const decryptedRoles = this.encryptionService.decryptData(localStorage.getItem(ROLES_STORAGE_KEY));
    this.isAdmin = decryptedRoles ? decryptedRoles.includes('ADMIN') : false;
  }

  ngOnInit(): void {
    this.affiliate = this.store.selectSnapshot(ActiveAffiliateState);
    this.fpso = this.store.selectSnapshot(ActiveFpsoState);
    this.isOverview = this.route.url.includes('overview');
    this.filtersAreDisplayed = !this.isOverview || this.fpso === 'PAZFLOR';
    this.onInitIsCalled = true;
    this.initGraph();
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  saveEdit() {
    this.updateOldDataWithNew();
    this.editMode = !this.editMode;
    this.showAllData.emit(false);
    this.baselineService.updateBaseline(this.affiliate, this.fpso, this.equipmentName, this.firstPoint[0], this.lastPoint[0], this.firstPoint[1], this.lastPoint[1]).subscribe((response) => {
      this.toastService.showToast('Baseline updated within 24 hours');
      this.showBaseline();
    });
  }

  toggleEditBaseline() {
    this.editMode = !this.editMode;
    this.editToggled.emit(this.editMode);

    if (this.editMode) {
      // When entering edit mode, include modified options and update graphic elements
      this.updateOldDataWithNew();
      this.includeModifiedOptions();
      this.showAllData.emit(true);
    } else {
      // When exiting edit mode, remove or reset draggable elements if needed
      this.resetGraph(); // Remove baseline series if that's part of the reset
      this.showAllData.emit(false);
    }
  }


  resetGraph(): void {
    this.updateNewDataWithOld();
    this.editMode = !this.editMode;
    this.showAllData.emit(false);
  }

  handleCoordinatesChange(event: { firstPoint: number[], lastPoint: number[] }): void {
    this.firstPoint = event.firstPoint;
    this.lastPoint = event.lastPoint;
  }

  includeModifiedOptions() {
    if (this.isAdmin) {
      if (this.crossPlotGraphInput && Array.isArray(this.crossPlotGraphInput.series)) {
        const baselineSerie = this.crossPlotGraphInput.series.find(serie => serie.name === 'Baseline');

        if (baselineSerie) {
          if ('data' in baselineSerie && Array.isArray(baselineSerie.data)) {
            this.firstPoint = this.firstPoint ? this.firstPoint : baselineSerie.data[0];
            this.lastPoint = this.lastPoint ? this.lastPoint : baselineSerie?.data[baselineSerie?.data.length - 1];
            this.modifiedLineSeries = {
              name: 'New Baseline',
              type: 'line',
              smooth: true,
              progressiveThreshold: 10000,
              z: 0,
              data: [ this.firstPoint, this.lastPoint ],
              color: 'green'
            };

            const updatedSeries = [ ...this.crossPlotGraphInput.series ];
            const existingSeriesIndex = updatedSeries.findIndex(serie => serie.name === 'New Baseline');
            if (existingSeriesIndex !== -1) {
              updatedSeries[existingSeriesIndex] = this.modifiedLineSeries;
            } else {
              updatedSeries.push(this.modifiedLineSeries);
            }

            this.crossPlotGraphInput = { ...this._crossPlotGraphInput, series: updatedSeries };
          } else {
            console.log('Baseline series does not contain data.');
          }
        } else {
          console.log('Baseline series not found.');
        }
      } else {
        console.error('Series is not an array.');
      }
    }
  }

  private showBaseline() {
    if (this.isAdmin && this._equipmentName && this._crossPlotGraphInput) {
      this.baselineService.getBaseline(this.affiliate, this.fpso, this._equipmentName === this.fpso ? '' : this._equipmentName).subscribe(baselines => {
        const baselineNew = baselines[0].baselineModelNew;
        this.newBaselineUpdatedBy = baselineNew.updatedBy;
        this.newBaselineUpdatedAt = baselineNew.updatedAt;
        this.firstPoint = [ baselineNew.xMin, baselineNew.yMin ];
        this.lastPoint = [ baselineNew.xMax, baselineNew.yMax ];
        this.includeModifiedOptions();
        this.cdr.detectChanges();
      })
    }
  }

  public mustShowNewBaseline() {
    return isToday(this.newBaselineUpdatedAt);
  }

  private updateOldDataWithNew() {
    this._crossPlotGraphInput_Old = this.crossPlotGraphInput;
    this.firstPoint_OLD = this.firstPoint;
    this.lastPoint_OLD = this.lastPoint;
  }

  private updateNewDataWithOld() {
    this.crossPlotGraphInput = this._crossPlotGraphInput_Old;
    this.firstPoint = this.firstPoint_OLD;
    this.lastPoint = this.lastPoint_OLD;
  }
}

