import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Store } from "@ngxs/store";
import { ActivatedRoute } from "@angular/router";
import { GetActiveAffiliate, GetActiveFpso } from "../../../../statemanagement";
import { Affiliate } from "../../../types";
import { AlertService } from 'src/modules/app/services/alerts/alerts.service';

@Component({
  selector: 'co2-private-layout',
  templateUrl: './private-layout.container.html',
  styleUrls: ['./private-layout.container.scss']
})
export class PrivateLayoutContainer implements OnInit, AfterViewInit {

  @ViewChild('dummyDiv', { static: true }) dummyDiv!: ElementRef;
  scrollToTopButtonImage: string = 'assets/scroll_to_top_button_inactive.png';
  private scrollTimeout: any;
  showFooter = false;

  constructor(private route: ActivatedRoute, private store: Store, private alertService: AlertService) {}

  ngOnInit(): void {
    this.route.firstChild?.params.subscribe(params => {
      const [affiliate, fpso] = [params['affiliate'], params['fpso-name']];
      if (Object.values(Affiliate).includes(affiliate)) {
        this.store.dispatch(new GetActiveAffiliate(affiliate));
        this.store.dispatch(new GetActiveFpso(fpso));
      }
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    this.alertService.alertsLoaded$.subscribe(noMoreAlerts => {
      this.showFooter = noMoreAlerts;
    });
    this.scrollToTopButtonImage = 'assets/scroll_to_top_button_inactive.png';
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.scrollToTopButtonImage = 'assets/scroll_to_top_button_active.png';
    }, 200);
  }

  ngAfterViewInit() {
    const observer = new IntersectionObserver(([entry]) => {
      const scrollToTopButton = document.getElementById('scrollToTopButton');
      if (scrollToTopButton) {
        scrollToTopButton.style.display = entry.isIntersecting ? 'none' : 'block';
      }
    });

    observer.observe(this.dummyDiv.nativeElement);
  }

  scrollToTop(): void {
    this.dummyDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}
