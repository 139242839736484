<div class="tooltip"
     [ngClass]="['tooltip--'+position, 'tooltip--'+theme]"
     [class.tooltip--visible]="visible"
     [style.left]="left + 'px'"
     [style.top]="top + 'px'">
  <br/>
  <div class="status__bubble">
    <section *ngIf="tooltip === 'Status'; else elseBlock">
      <div class="status__table__row">
        <div class="status__table__col_1"><span class="status__commented"><span class="material-icons status__commented__icon"> circle </span> Not Commented </span></div>
        <div class="status__table__col_2">Deviation that has not been commented on in the discussion feature.</div>
      </div>
    </section>
    <ng-template #elseBlock>
      <div class="tooltip__message" [innerHTML]="tooltip"></div>
    </ng-template>
  </div>
  <br/>
</div>
