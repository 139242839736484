import { EChartsOption } from "echarts";
import {calculateScale} from "../../types";

export const SANKEY_GRAPH: EChartsOption = {
  series: {
    type: 'sankey',
    levels: [
      {
        depth: 0,
        itemStyle: {
          color: '#D68100'
        }
      },
      {
        depth: 1,
        itemStyle: {
          color: '#00838F'
        }
      },
      {
        depth: 2,
        itemStyle: {
          color: '#ED0000'
        }
      }
    ],
    emphasis: {
      focus: 'adjacency'
    },
    nodeAlign: 'left',
    layoutIterations: 0,
    nodeGap: 10,
    draggable: false,
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove'
    },
    data: [],
    links: [],
    lineStyle: {
      color: 'source',
      curveness: 0.5
    }
  }
};

export const buildSankeyLabelFormatter = () => {
  return (params: any) => {
    const calculatedScale = calculateScale(params.value);
    const separation = params.name.length > 25 ? '\n' : ': ';
    return `${ params.name }${ separation }${ params.value.toFixed(calculatedScale) }`
  }
}
