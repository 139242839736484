import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './modules/app/app.module';
import { environment } from '@environments/environment';

/**
 * Enables Hotjar, using a given hotjar site id.
 *
 * @param hjid hotjar id.
 *
 * The SHA256 to be used for let CSP's script-src-elem policy allow Hotjar usage can be computed in a bash shell:
 *
 * ```
 * echo -n "$v" |  openssl sha256 -binary | openssl base64
 * ```
 * where v has the value of hjScriptEl.innerHTML.
 * This value depends on `hjid`.
 *
 */
const enableHotjar = (hjid: string): void => {
  const hjScriptEl = document.createElement('script');
  hjScriptEl.innerHTML = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
   h._hjSettings={hjid:'${hjid}',hjsv:6}; a=o.getElementsByTagName('head')[0];
   r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })
   (window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  document.head.appendChild(hjScriptEl);
};

if (environment.production) {
  enableProdMode();
}

if (environment.hotjarEnvironmentId && environment.hotjarEnvironmentId !== '') {
  enableHotjar(environment.hotjarEnvironmentId);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {
    // eslint-disable-next-line no-console
    console.error(err)
  });
