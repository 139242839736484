import { Injectable } from '@angular/core';
import { EncryptionService } from "../../services/encryption/encryption.service";
import { ROLES_STORAGE_KEY } from "../../containers";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard {

  constructor(private router: Router, private encryptionService: EncryptionService) {
  }

  canActivate(): boolean {
    if (!this.hasAdminRole()) {
      this.router.navigate(['unauthorized']);
      return false;
    }
    return true;
  }

  hasAdminRole(): boolean {
    return this.encryptionService.hasAdminRole(localStorage.getItem(ROLES_STORAGE_KEY));
  }

}
