import { Component, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import {
  DATE_FORMAT,
  formatDateFromTo,
  FRENCH_DATE_FORMAT
} from "../../utils/date-time-picker/date-time-picker";
import * as moment from "moment";

const START_DATE_CONTROL_NAME = "startDate";
const END_DATE_CONTROL_NAME = 'endDate';

@Component({
  selector: 'co2-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: [ './date-selection.component.scss' ]
})
export class DateSelectionComponent {

  @Input()
  enableLimitationMinDate: boolean = false;

  _startDate!: Date | string;
  _endDate!: Date | string;

  @Input()
  set startDate(value: Date | string) {
    if (value && value !== this._startDate) {
      this.selectedQuickValue = '';
    }
    this._startDate = value;
    this.startDateFormControl.setValue(value);
  }

  get startDate() {
    return this._startDate;
  }

  @Input()
  set endDate(value: Date | string) {
    if (value && value !== this._endDate) {
      this.selectedQuickValue = '';
    }
    this._endDate = value;
    this.endDateFormControl.setValue(value);
  }

  get endDate() {
    return this._endDate;
  }

  @Input() quickSelection?: boolean = false;
  @Output() datePick = new EventEmitter<any>();

  get minDate(): Date | null {
    return this.enableLimitationMinDate ? moment().subtract(3, 'years').toDate() : null;
  };

  get maxDate(): Date {
    return moment().toDate();
  };

  formGroup: FormGroup = new FormGroup({
    startDate: new FormControl([ '', Validators.required ]),
    endDate: new FormControl([ '', Validators.required ])
  }, { validators: this.dateLessThanEqual(START_DATE_CONTROL_NAME, END_DATE_CONTROL_NAME) });

  selectedQuickValue: string = '';

  get startDateFormControl() {
    return this.formGroup.controls[START_DATE_CONTROL_NAME] as FormControl;
  }

  get endDateFormControl() {
    return this.formGroup.controls[END_DATE_CONTROL_NAME] as FormControl;
  }

  dateLessThanEqual(startControlName: string, endControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const group = control as FormGroup;
      const startControl = group.controls[startControlName];
      const endControl = group.controls[endControlName];
      if (startControl.value > endControl.value) {
        return { 'dateInvalid': true };
      }
      return null;
    };
  }

  onDateChange(dateType: any, date: Date): void {
    if (date) {
      if (dateType === START_DATE_CONTROL_NAME) {
        this.startDate = date;
      } else if (dateType === END_DATE_CONTROL_NAME) {
        this.endDate = date;
      }
      this.selectedQuickValue = '-1';
      this.emitPeriod();
    }
  }

  private emitPeriod() {
    if (!this._startDate || !this._endDate || this._startDate <= this._endDate) {
      this.datePick.emit({
        startDate: this.startDate,
        endDate: this.endDate
      });
    } else {
      console.warn("invalid dates");
    }
  }

  onQuickSelectionChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    const now = moment().toDate();
    switch (selectedValue) {
      case '1':
        const lastWeekStart = moment().subtract(1, 'weeks').toDate();
        this.updateStartDate(lastWeekStart);
        break;
      case '2':
        const lastMonthStart = moment().subtract(1, 'months').toDate();
        this.updateStartDate(lastMonthStart);
        break;
      case '3':
        const lastThreeMonthsStart = moment().subtract(3, 'months').toDate();
        this.updateStartDate(lastThreeMonthsStart);
        break;
      case '4':
        const lastSixMonthsStart = moment().subtract(6, 'months').toDate();
        this.updateStartDate(lastSixMonthsStart);
        break;
      default:
        const defaultStartDate = moment().subtract(3, 'years').toDate();
        this.updateStartDate(defaultStartDate);
        break;
    }
    this.updateEndDate(now);
    this.emitPeriod();
  }


  private updateEndDate(endDate: Date) {
    this._endDate = endDate;
    this.endDateFormControl.setValue(endDate);
  }

  private updateStartDate(startDate: Date) {
    this._startDate = startDate;
    this.startDateFormControl.setValue(startDate);
  }

  onEnter(dateType: string, $event: any) {
    const actualDate = $event.target.value;
    if (actualDate) {
    const formattedDate = formatDateFromTo(actualDate, FRENCH_DATE_FORMAT, DATE_FORMAT);
      this.onDateChange(dateType, new Date(formattedDate));
    }
  }
}
