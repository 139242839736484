<div class="card">
  <div class="card-title">
    <span class="material-icons-round card-header-icon">graphic_eq&nbsp;</span>
    Energy Performance
  </div>

  <div class="card-body">
    <co2-loading-spinner [isLoading]="isLoading">
      <co2-ui-graph [options]="energyPerfGraphInputs"></co2-ui-graph>
      <co2-card-error *ngIf="hasLoadingError" (tryAgain)="reloadData.emit()"></co2-card-error>
    </co2-loading-spinner>

    <co2-influential-factors [energyType]="energyType"
                             [powerGraphInputs]="powerGraphInputs"
                             [powerGraphIsLoading]="powerGraphIsLoading"
                             [hasPowerGraphLoadingError]="hasPowerGraphLoadingError"
                             (reloadPowerGraphData)="reloadPowerGraphData.emit()"

                             [asvGraphInputs]="asvGraphInputs"
                             [asvGraphIsLoading]="asvGraphIsLoading"
                             [hasAsvGraphLoadingError]="hasAsvGraphLoadingError"
                             (reloadAsvGraphData)="reloadAsvGraphData.emit()"

                             [polytropicGraphInputs]="polytropicGraphInputs"
                             [polytropicGraphIsLoading]="polytropicGraphIsLoading"
                             [hasPolytropicGraphLoadingError]="hasPolytropicGraphLoadingError"
                             (reloadPolytropicGraphData)="reloadPolytropicGraphData.emit()"

                             (toggleGraph)="toggleGraph.emit($event)">
    </co2-influential-factors>
  </div>
</div>
