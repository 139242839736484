import { Observations, ObservationsWarnings } from '../../app/types';

export class GetWarningsData {
  static readonly type = '[Warnings] Get warnings';

  constructor(public payload: ObservationsWarnings, public resetState: boolean = false) {
  }
}

export class ClearWarningsData {
  static readonly type = '[Warnings] Clear warnings';
}
