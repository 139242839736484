import { Injectable } from '@angular/core';
import { BehaviorSubject, defaultIfEmpty, map, Observable, zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import {
  ActiveAffiliateState,
  ActiveCompressorsTypeState,
  ActiveFpsoConfigState,
  ActiveFpsoState,
  ActiveSeaWaterLiftTypeState,
  ActiveTurbineTabState,
  ActiveWaterInjectionTypeState,
  AlertsDataState,
  ClearAlertsData,
  CompressorCrossPlotState,
  CompressorsAsvDataState,
  CompressorsHourlyPerformanceDriftState,
  CompressorsOverviewKpisState,
  CompressorsPerformanceDriftState,
  CompressorsPolytropicDataState,
  CompressorsPowerDataState,
  GetActiveAffiliate,
  GetActiveCompressorsType,
  GetActiveFpso,
  GetActiveFpsoConfig,
  GetActiveSeaWaterLiftType,
  GetActiveTurbineTab,
  GetActiveWaterInjectionType,
  GetAlertsData,
  GetCompressorsAsvData,
  GetCompressorsCrossPlot,
  GetCompressorsHourlyPerformanceDrift,
  GetCompressorsOverviewKpis,
  GetCompressorsPerformanceDrift,
  GetCompressorsPolytropicData,
  GetCompressorsPowerData,
  GetSeaWaterLiftCrossPlot,
  GetTotalCo2Yearly,
  GetTurbogenCo2Factor,
  GetTurbogenCrossPlot,
  GetTurbogenHourlyPerformanceDrift,
  GetTurbogenHourlyPowerTurbines, GetTurbogenOverviewKpis,
  GetTurbogenPerformanceDrift,
  GetTurbogenPowerReserve,
  GetTurbogenPowerTurbines, GetUserName, GetWaterInjectionCrossPlot, GetWaterInjectionPerformanceDrift,
  SeaWaterLiftCrossPlotState,
  TotalCo2YearlyState,
  TurbogenCo2FactorState,
  TurbogenCrossPlotState,
  TurbogenHourlyPerformanceDriftState,
  TurbogenHourlyPowerTurbinesState, TurbogenOverviewKpisState,
  TurbogenPerformanceDriftState,
  TurbogenPowerReserveState,
  TurbogenPowerTurbinesState,
  UserNameState,
  WaterInjectionCrossPlotState,
  WaterInjectionPerformanceDriftState,
} from '../statemanagement';
import { AuthService, CompressorsService, GeneralDashboardService, TurbogeneratorsService } from './services';
import {
  Affiliate,
  BASELINES_DEFAULT_COLORS,
  buildBaselineModel,
  CompressorIndicator,
  CROSS_PLOT,
  CROSS_PLOT_MULTIPLE,
  CrossPlot,
  CrossPlotMetadata,
  DatePick,
  DatePicker,
  IndicatorInfos,
  LONG_LEGEND_BOTTOM,
  Observations,
  ObservationsWarnings,
  TabItem,
  TurbogenPowerReserve, ValueSeries,
} from './types';
import { AccountInfo } from '@azure/msal-browser';
import { TotalYearlyCo2Emission } from './types/total-yearly-co2-emission';
import { AlertService } from './services/alerts/alerts.service';
import { crossPlotSpanFormatter, crossplotTooltipFormatter, tooltipDatesFormatter } from "./utils";
import {
  ActivePumpEquipmentNameState,
  GetActivePumpEquipmentName
} from "../statemanagement/active-pump-equipment-name";
import { PumpCrossPlotState, PumpFeedCrossPlot } from "../statemanagement/pump-cross-plot";
import {
  GetSeaWaterLiftPerformanceDrift,
  SeaWaterLiftPerformanceDriftState
} from "../statemanagement/sea-water-lift-performance-drift";
import { PumpFeedPerformanceDrift, PumpPerformanceDriftState } from "../statemanagement/pump-performance-drift";
import {
  GetWaterInjectionHourlyPerformanceDrift,
  WaterInjectionHourlyPerformanceDriftState
} from "../statemanagement/water-injection-hourly-performance-drift";
import {
  GetSeaWaterLiftHourlyPerformanceDrift,
  SeaWaterLiftHourlyPerformanceDriftState
} from "../statemanagement/sea-water-lift-hourly-performance-drift";
import {
  GetPumpHourlyPerformanceDrift,
  PumpHourlyPerformanceDriftState
} from "../statemanagement/pump-hourly-performance-drift";
import { GetTabsElements } from '../statemanagement/tabs/actions';
import { TabsElementsState } from '../statemanagement/tabs/state';
import { Tab } from './types/tab.interface';
import { combineObservables } from "./utils/observables/observables-tools";
import { PerformanceDriftService } from "./services/performance-drift/performance-drift.service";
import { CrossplotService } from "./services/crossplot/crossplot.service";
import { EquipmentType } from "./types/equipment-type.enum";
import { KpiOverview } from "./types/kpi-overview.interface";
import { GetPumpOverviewKpis, PumpOverviewKpisState } from "../statemanagement/pump-overview-kpis";
import { cloneObject } from "./utils/object-manipulation/object-manipulation";
import { KpiService } from "./services/kpi/kpi.service";
import { GetTotalAvoidedCo2Yearly, TotalAvoidedCo2State } from "../statemanagement/total-avoided-co2";
import { SankeyService } from "./services/sankey/sankey.service";
import { Router } from "@angular/router";
import { FrontConfigsService } from "./services/front-configs/front-configs.service";
import { EquipmentResponse } from "./types/front-config.interface";
import { IndexedDbService } from "./services/storage/indexed-db.service";
import { FAVORITE_ASSET_STORAGE_KEY } from "./containers";
import { ClearWarningsData, GetWarningsData, WarningssDataState } from '../statemanagement/warnings';
import { WarningService } from './services/warnings/warnings.service';

export const OVERVIEW = 'overview';

@Injectable({
  providedIn: 'root',
})
export class AppSandbox {
  @Select(TotalCo2YearlyState) totalCo2YearlyEmission$!: Observable<IndicatorInfos>;
  @Select(TotalAvoidedCo2State) totalAvoidedCo2$!: Observable<IndicatorInfos>;
  totalCo2YearlyEmissionLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  totalCo2YearlyEmissionError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(ActiveCompressorsTypeState) activeCompressorsType$!: Observable<string>;

  @Select(UserNameState) userName$!: Observable<string>;

  @Select(CompressorsPowerDataState) compressorsPowerData$!: Observable<ValueSeries[]>;
  compressorsPowerDataLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  compressorsPowerDataError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(CompressorsAsvDataState) compressorsAsvData$!: Observable<ValueSeries[]>;
  compressorsAsvDataLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  compressorsAsvDataError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(CompressorsPolytropicDataState) compressorsPolytropicData$!: Observable<ValueSeries[]>;
  compressorsPolytropicDataLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  compressorsPolytropicDataError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(CompressorsOverviewKpisState) compressorsOverviewKpis$!: Observable<KpiOverview>;
  compressorsOverviewKpisLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  compressorsOverviewKpisError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(PumpOverviewKpisState) pumpOverviewKpis$!: Observable<KpiOverview>;

  @Select(TurbogenOverviewKpisState) turbogenOverviewKpis$!: Observable<KpiOverview>;

  @Select(TurbogenPowerReserveState) turbogenPowerReserve$!: Observable<TurbogenPowerReserve>;
  turbogenPowerReserveLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  turbogenPowerReserveError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(TurbogenCo2FactorState) turbogenCo2FactorData$!: Observable<ValueSeries[]>;
  turbogenCo2FactorDataLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  turbogenCo2FactorDataError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(ActiveFpsoState) activeFpso$!: Observable<string>;

  @Select(ActiveFpsoConfigState) activeFpsoConfig$!: Observable<string>;

  @Select(ActiveAffiliateState) activeAffiliate$!: Observable<Affiliate>;

  @Select(ActiveTurbineTabState) activeTurbineTab$!: Observable<string>;

  @Select(ActiveSeaWaterLiftTypeState) activeSeaWaterLiftType$!: Observable<string>;

  @Select(ActiveWaterInjectionTypeState) activeWaterInjectionType$!: Observable<string>;

  @Select(ActivePumpEquipmentNameState) activePumpType$!: Observable<string>;

  @Select(TurbogenCrossPlotState) turbogenCrossPlot$!: Observable<CrossPlot[]>;
  turbogenCrossPlotLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  turbogenCrossPlotError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(CompressorCrossPlotState) compressorCrossPlot$!: Observable<CrossPlot[]>;
  compressorCrossPlotLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  compressorCrossPlotError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  compressorsPerformanceDriftLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  compressorsPerformanceDriftError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  compressorsHourlyPerformanceDriftLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  compressorsHourlyPerformanceDriftError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  combinedCompressorsPerformanceDriftLoader$: Observable<boolean> = combineObservables([ this.compressorsPerformanceDriftLoader$, this.compressorsHourlyPerformanceDriftLoader$ ], true);

  combinedCompressorsPerformanceDriftError$: Observable<boolean> = combineObservables<boolean>([ this.compressorsPerformanceDriftError$, this.compressorsHourlyPerformanceDriftError$ ], true);
  noAvailableData: boolean = false;

  @Select(TabsElementsState) tabsElements$!: Observable<any>;
  tabsElementsLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tabsElementsError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(SeaWaterLiftCrossPlotState) seaWaterLiftCrossPlot$!: Observable<CrossPlot[]>;
  @Select(WaterInjectionCrossPlotState) waterInjectionCrossPlot$!: Observable<CrossPlot[]>;
  @Select(PumpCrossPlotState) pumpCrossPlot$!: Observable<CrossPlot[]>;
  pumpsCrossPlotLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pumpsCrossPlotError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(WaterInjectionPerformanceDriftState) waterInjectionPerformanceDrift$!: Observable<ValueSeries[]>;
  @Select(SeaWaterLiftPerformanceDriftState) seaWaterLiftPerformanceDrift$!: Observable<ValueSeries[]>;
  @Select(PumpPerformanceDriftState) pumpPerformanceDrift$!: Observable<ValueSeries[]>;
  @Select(CompressorsPerformanceDriftState) compressorsPerformanceDrift$!: Observable<ValueSeries[]>;
  pumpsPerformanceDriftLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pumpsPerformanceDriftError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(WaterInjectionHourlyPerformanceDriftState) waterInjectionHourlyPerformanceDrift$!: Observable<ValueSeries[]>;
  @Select(SeaWaterLiftHourlyPerformanceDriftState) seaWaterLiftHourlyPerformanceDrift$!: Observable<ValueSeries[]>;
  @Select(PumpHourlyPerformanceDriftState) pumpHourlyPerformanceDrift$!: Observable<ValueSeries[]>;
  @Select(CompressorsHourlyPerformanceDriftState) compressorsHourlyPerformanceDrift$!: Observable<ValueSeries[]>;
  pumpsHourlyPerformanceDriftLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pumpsHourlyPerformanceDriftError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  combinedPumpsPerformanceDriftLoader$: Observable<boolean>
    = combineObservables([ this.pumpsPerformanceDriftLoader$,
    this.pumpsHourlyPerformanceDriftLoader$ ], true);

  combinedPumpsPerformanceDriftError$: Observable<boolean>
    = combineObservables<boolean>([ this.pumpsPerformanceDriftError$,
    this.pumpsHourlyPerformanceDriftError$ ], true);

  @Select(TurbogenPerformanceDriftState) turbogenPerformanceDrift$!: Observable<ValueSeries[]>;
  turbogenPerformanceDriftLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  turbogenPerformanceDriftError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(TurbogenPowerTurbinesState) turbogenPowerTurbines$!: Observable<ValueSeries>;
  turbogenPowerTurbinesLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  turbogenPowerTurbinesError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(TurbogenHourlyPerformanceDriftState) turbogenHourlyPerformanceDrift$!: Observable<ValueSeries[]>;
  turbogenHourlyPerformanceDriftLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  turbogenHourlyPerformanceDriftError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(AlertsDataState) alertTable$!: Observable<Observations>;
  alertTableLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  alertTableWithChartsLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  alertTableError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  allAlertsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  noMoreAlertToLoad$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(WarningssDataState) warningTable$!: Observable<ObservationsWarnings>;
  warningsTableLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  warningTableWithChartsLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  warningTableError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  allWarningsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Select(TurbogenHourlyPowerTurbinesState) turbogenHourlyPowerTurbines$!: Observable<ValueSeries>;
  turbogenHourlyPowerTurbinesLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  turbogenHourlyPowerTurbinesError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private store: Store,
              private warningService: WarningService,
              private generalDashboardService: GeneralDashboardService,
              private compressorsService: CompressorsService,
              private authService: AuthService,
              private turbogeneratorsService: TurbogeneratorsService,
              private crossplotService: CrossplotService,
              private alertsService: AlertService,
              private kpiService: KpiService,
              private driftService: PerformanceDriftService,
              private sankeyService: SankeyService,
              private frontConfigsService: FrontConfigsService,
              private indexedDbService: IndexedDbService,
              private router: Router
  ) {
  }

  loadTotalCo2YearlyEmission(): void {
    this.generalDashboardService.getTotalYearlyCo2Emission(
      this.store.selectSnapshot(ActiveAffiliateState),
      this.store.selectSnapshot(ActiveFpsoState)
    ).then((data: TotalYearlyCo2Emission) => {
      if (data) {
        this.store.dispatch(new GetTotalCo2Yearly(data));
        this.store.dispatch(new GetTotalAvoidedCo2Yearly(data));
      }
    });
  }

  loadSankey() {
    return this.sankeyService.getSankey(
      this.store.selectSnapshot(ActiveAffiliateState),
      this.store.selectSnapshot(ActiveFpsoState));
  }

  setActiveCompressorsType(equipmentName: string): void {
    this.store.dispatch(new GetActiveCompressorsType(equipmentName));
  }

  loadUserName(): void {
    this.authService.currentUser.pipe(
      tap((user: AccountInfo | null) => {
        if (user?.name) this.store.dispatch(new GetUserName(user.name));
      })
    ).subscribe();
  }

  loadCompressorsEmissionData(indicator: CompressorIndicator, compressorType: string): void {
    this.compressorsService
      .getCompressorsEmissionData(compressorType, this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), indicator)
      .pipe(
        tap((data: ValueSeries[]) => {
          if (indicator === CompressorIndicator.ASV)
            this.store.dispatch(new GetCompressorsAsvData(data));

          if (indicator === CompressorIndicator.POLYTROPIC_EFFICIENCY)
            this.store.dispatch(new GetCompressorsPolytropicData(data));

          if (indicator === CompressorIndicator.POWER)
            this.store.dispatch(new GetCompressorsPowerData(data));
        })
      ).subscribe();
  }

  sortStagesAlphabeticallyInArray(data: any): any {
    // Ensure the kpiByEquipments property exists and is an array
    if (data.kpiByEquipments && Array.isArray(data.kpiByEquipments)) {
      data.kpiByEquipments.forEach((equipment: any) => {
        if (equipment.stages && Array.isArray(equipment.stages)) {
          // Sort the stages array alphabetically by stageName (case-insensitive)
          equipment.stages.sort((a: any, b: any) => {
            const stageNameA = a.stageName.toLowerCase();
            const stageNameB = b.stageName.toLowerCase();
            if (stageNameA < stageNameB) return -1;
            if (stageNameA > stageNameB) return 1;
            return 0;
          });
        }
      });
    }
    return data;
  }

  loadCompressorsOverviewKpis(allEquipments: string[]): void {
    this.kpiService
      .getOverviewKpis(this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), EquipmentType.COMPRESSOR, allEquipments)
      .pipe(
        tap((data: KpiOverview) => {
          const sortedData = this.sortStagesAlphabeticallyInArray(data);
          this.store.dispatch(new GetCompressorsOverviewKpis(sortedData));
        })
      ).subscribe();
  }


  loadPumpsOverviewKpis(systemType: string, allEquipmentNames: string[]): void {
    this.kpiService
      .getOverviewKpis(this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), systemType, this.getPumpEquipmentNames(allEquipmentNames))
      .pipe(
        tap((data: KpiOverview) => this.store.dispatch(new GetPumpOverviewKpis(data))),
      ).subscribe();
  }

  loadTurbogenOverviewKpis(systemType: string, allEquipmentNames: string[]): void {
    this.kpiService
      .getOverviewKpis(this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), systemType, this.getTurbogenEquipmentNames(allEquipmentNames))
      .pipe(
        tap((data: KpiOverview) => this.store.dispatch(new GetTurbogenOverviewKpis(data))),
      ).subscribe();
  }

  loadTurbogenPowerReserve(): void {
    if (this.store.selectSnapshot(ActiveFpsoConfigState)) {
      this.turbogeneratorsService
        .getTurbogenPowerReserve(this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), this.store.selectSnapshot(ActiveFpsoConfigState))
        .pipe(
          tap((data: TurbogenPowerReserve) => this.store.dispatch(new GetTurbogenPowerReserve(data))),
        ).subscribe();
    }
  }

  loadTurbogenCo2FactorData(): void {
    this.turbogeneratorsService
      .getTurbogenCo2FactorData(this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState))
      .pipe(
        tap((data: ValueSeries[]) => this.store.dispatch(new GetTurbogenCo2Factor(data))),
      ).subscribe();
  }

  setActiveFpso(fpso: string): void {
    this.resetAllStates();
    this.store.dispatch(new GetActiveFpso(fpso));
    this.indexedDbService.clearData().then(r => {

      const asset = JSON.parse(localStorage.getItem(FAVORITE_ASSET_STORAGE_KEY) ?? '{}');
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([ `/${ asset.affiliate }/${ asset.fpsos }/general-dashboard` ]);
      });
    });
  }

  setActiveFpsoConfig(fpsoConfig: string): void {
    this.store.dispatch(new GetActiveFpsoConfig(fpsoConfig));
  }

  setActiveTurbineTab(turbineTab: string): void {
    this.store.dispatch(new GetActiveTurbineTab(turbineTab));
  }

  setActiveSeaWaterLiftTab(seaWaterLiftTab: string): void {
    this.store.dispatch(new GetActiveSeaWaterLiftType(seaWaterLiftTab));
  }

  setActiveWaterInjectionTab(waterInjectionTab: string): void {
    this.store.dispatch(new GetActiveWaterInjectionType(waterInjectionTab));
  }

  setActiveEquipmentNameTab(equipmentName: string): void {
    this.store.dispatch(new GetActivePumpEquipmentName(equipmentName));
  }

  getSubTabs(systemType: string): Observable<TabItem[]> {
    return this.frontConfigsService.getSubTabs(this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), systemType);
  }

  getSelectedEquipment(systemType: string): Observable<EquipmentResponse> {
    return this.frontConfigsService.getSelectedEquipment(this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), systemType);
  }

  loadTurbogenCrossPlot(allEquipment: string[]): void {
    this.crossplotService
      .getCrossPlot(EquipmentType.TURBOGEN, this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), this.getTurbogenEquipmentNames(allEquipment), allEquipment)
      .pipe(
        tap((data: CrossPlot[]) => this.store.dispatch(new GetTurbogenCrossPlot(data))),
      ).subscribe();
  }

  loadCompressorCrossPlot(allEquipment: string[], equipmentsByType: Map<string, string[]>): void {
    this.crossplotService
      .getCrossPlot(EquipmentType.COMPRESSOR, this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), this.getCompressorEquipmentNames(allEquipment, equipmentsByType), [])
      .pipe(
        tap((data: CrossPlot[]) => this.store.dispatch(new GetCompressorsCrossPlot(data))),
      ).subscribe();
  }

  loadPumpCrossPlot(systemType: string, allEquipment: string[]): void {
    this.crossplotService
      .getCrossPlot(systemType, this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), this.getPumpEquipmentNames(allEquipment), allEquipment)
      .pipe(
        tap((data: CrossPlot[]) => this.store.dispatch(new PumpFeedCrossPlot(data))),
      ).subscribe();
  }

  loadTurbogenPerformanceDrift(systemType: string, startDate: string, allEquipments: string[]): void {
    const calls = this.getTurbogenEquipmentNames(allEquipments).map(equipmentName => this.driftService
      .getDailyPerformanceDrift(this.store.selectSnapshot(ActiveAffiliateState),
        this.store.selectSnapshot(ActiveFpsoState), startDate, systemType, equipmentName));
    zip(...calls)
      .pipe(
        map(results => results.flatMap(result => result))
      )
      .subscribe((data: ValueSeries[]) => {
        this.store.dispatch(new GetTurbogenPerformanceDrift(data))
      });
  }

  loadPumpPerformanceDrift(systemType: string, startDate: string, allEquipments: string[]): void {
    const calls = this.getPumpEquipmentNames(allEquipments).map(equipmentName => this.driftService
      .getDailyPerformanceDrift(this.store.selectSnapshot(ActiveAffiliateState),
        this.store.selectSnapshot(ActiveFpsoState), startDate, systemType, equipmentName));
    zip(...calls)
      .pipe(
        map(results => results.flatMap(result => result))
      )
      .subscribe((data: ValueSeries[]) => {
        this.store.dispatch(new PumpFeedPerformanceDrift(data))
      });
  }

  loadCompressorsPerformanceDrift(systemType: string, startDate: string, allEquipments: string[], equipmentsByType: Map<string, string[]>): void {
    const calls = this.getCompressorEquipmentNames(allEquipments, equipmentsByType).map(equipmentName => this.driftService
      .getDailyPerformanceDrift(this.store.selectSnapshot(ActiveAffiliateState),
        this.store.selectSnapshot(ActiveFpsoState), startDate, systemType, equipmentName));
    zip(...calls)
      .pipe(
        map(results => results.flatMap(result => result))
      )
      .subscribe((data: ValueSeries[]) => {
        this.store.dispatch(new GetCompressorsPerformanceDrift(data))
      });
  }

  loadPumpHourlyPerformanceDrift(systemType: string, allEquipments: string[]): void {
    const calls = this.getPumpEquipmentNames(allEquipments).map(equipmentName => this.driftService
      .getHourlyPerformanceDrift(this.store.selectSnapshot(ActiveAffiliateState),
        this.store.selectSnapshot(ActiveFpsoState), systemType,
        equipmentName));
    zip(...calls)
      .pipe(
        map(results => results.flatMap(result => result))
      )
      .subscribe((data: ValueSeries[]) => {
        this.store.dispatch(new GetPumpHourlyPerformanceDrift(data))
      });
  }

  loadCompressorsHourlyPerformanceDrift(systemType: string, allEquipments: string[], equipmentsByType: Map<string, string[]>): void {
    const calls = this.getCompressorEquipmentNames(allEquipments, equipmentsByType).map(equipmentName => this.driftService
      .getHourlyPerformanceDrift(this.store.selectSnapshot(ActiveAffiliateState),
        this.store.selectSnapshot(ActiveFpsoState), systemType,
        equipmentName));
    zip(...calls)
      .pipe(
        map(results => results.flatMap(result => result))
      )
      .subscribe((data: ValueSeries[]) => {
        this.store.dispatch(new GetCompressorsHourlyPerformanceDrift(data))
      });
  }

  loadTurbogenHourlyPerformanceDrift(systemType: string, allEquipments: string[]): void {
    const calls = this.getTurbogenEquipmentNames(allEquipments).map(equipmentName => this.driftService
      .getHourlyPerformanceDrift(this.store.selectSnapshot(ActiveAffiliateState),
        this.store.selectSnapshot(ActiveFpsoState), systemType,
        equipmentName));
    zip(...calls)
      .pipe(
        map(results => results.flatMap(result => result))
      )
      .subscribe((data: ValueSeries[]) => {
        this.store.dispatch(new GetTurbogenHourlyPerformanceDrift(data))
      });
  }

  loadTurbogenPowerTurbines(startDate: string): void {
    this.turbogeneratorsService
      .getTurbogenPowerTurbines(this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), startDate)
      .pipe(
        tap((data: ValueSeries) => this.store.dispatch(new GetTurbogenPowerTurbines(data))),
      ).subscribe();
  }

  loadWarnings(index: number, resetState: boolean = false): void {
    this.warningTableWithChartsLoader$.next(true);
    this.warningService
      .getWarningsWithTrends(this.store.selectSnapshot(ActiveAffiliateState),
        this.store.selectSnapshot(ActiveFpsoState))
      .pipe(
        defaultIfEmpty({ warnings: [], totalSize: 0 } as ObservationsWarnings)
      ).subscribe((data: ObservationsWarnings) => {

        this.store.dispatch(new GetWarningsData(data, resetState));

      this.warningTableWithChartsLoader$.next(false);
    }, error => {
        console.warn(error);
      this.warningTableWithChartsLoader$.next(false);
    });
  }

  loadAlerts(index: number, alertTypes: string[], filter: string, resetState: boolean = false): void {
    this.alertTableWithChartsLoader$.next(true);
    this.allAlertsLoaded$.next(false);
    this.alertsService
      .getAlerts(this.store.selectSnapshot(ActiveAffiliateState),
        this.store.selectSnapshot(ActiveFpsoState), alertTypes, filter, index)
      .pipe(
        defaultIfEmpty({ alerts: [], totalSize: 0 } as Observations)
      ).subscribe((data: Observations) => {
      if (index > 0 && data.alerts.length === 0) {
        this.alertsService.setNoMoreAlerts(true);
        this.noMoreAlertToLoad$.next(true);
      } else {
        this.store.dispatch(new GetAlertsData(data, resetState));
      }
      this.allAlertsLoaded$.next(true);
      this.alertTableWithChartsLoader$.next(false);
    }, error => {
        console.warn(error);
        this.allAlertsLoaded$.next(true);
        this.alertTableWithChartsLoader$.next(false);
    });
  }

  loadTurbogenHourlyPowerTurbines(startDate: string): void {
    this.turbogeneratorsService
      .getTurbogenHourlyPowerTurbines(this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState), startDate)
      .pipe(
        tap((data: ValueSeries) => this.store.dispatch(new GetTurbogenHourlyPowerTurbines(data))),
      ).subscribe();
  }

  clearAlerts(): void {
    this.store.dispatch(new ClearAlertsData());
  }

  clearWarnings(): void {
    this.store.dispatch(new ClearWarningsData());
  }

  getActiveAffiliate(): Affiliate {
    return this.store.selectSnapshot(ActiveAffiliateState);
  }

  setActiveAffiliate(affiliate: Affiliate): void {
    this.store.dispatch(new GetActiveAffiliate(affiliate));
  }

  getActiveFpso(): string {
    return this.store.selectSnapshot(ActiveFpsoState);
  }

  loadTabsConfiguration() {
    this.frontConfigsService.getTabs(this.store.selectSnapshot(ActiveAffiliateState),
      this.store.selectSnapshot(ActiveFpsoState))
      .subscribe((data: Tab[]) => {
        if (data) {
          this.store.dispatch(new GetTabsElements(data));
        }
      });
  }

  resetAllStates() {
    this.store.dispatch(new GetTabsElements([])); // TabsElementsState
    this.store.dispatch(new GetTurbogenPowerReserve(undefined)); // TurbogenPowerReserveState
    this.store.dispatch(new GetTurbogenCo2Factor([])); // TurbogenCo2FactorState
    this.store.dispatch(new ClearAlertsData()); // AlertsDataState
    this.store.dispatch(new ClearWarningsData());


    this.resetAllKpisStates();
    this.resetAllGraphsStates();
  }

  resetAllKpisStates() {
    this.store.dispatch(new GetTotalCo2Yearly(undefined)); // TotalCo2YearlyState
    this.store.dispatch(new GetTotalAvoidedCo2Yearly(undefined)); // TotalAvoidedCo2State
    this.store.dispatch(new GetCompressorsOverviewKpis(undefined)); // GetCompressorsOverviewKpis
    this.store.dispatch(new GetPumpOverviewKpis(undefined)); // PumpOverviewKpisState
    this.store.dispatch(new GetTurbogenOverviewKpis(undefined)); // PumpOverviewKpisState
  }

  resetAllGraphsStates() {
    this.store.dispatch(new GetCompressorsPowerData(undefined)); // CompressorsPowerDataState
    this.store.dispatch(new GetCompressorsAsvData(undefined)); // CompressorsAsvDataState
    this.store.dispatch(new GetCompressorsPolytropicData(undefined)); // CompressorsPolytropicDataState
    this.store.dispatch(new GetCompressorsHourlyPerformanceDrift(undefined)); // CompressorsHourlyPerformanceDriftState
    this.store.dispatch(new GetCompressorsPerformanceDrift(undefined)); // CompressorsPerformanceDriftState
    this.store.dispatch(new GetTurbogenCrossPlot(undefined)); // TurbogenCrossPlotState
    this.store.dispatch(new GetCompressorsCrossPlot(undefined)); // CompressorCrossPlotState
    this.store.dispatch(new GetTurbogenPerformanceDrift(undefined)); // TurbogenPerformanceDriftState
    this.store.dispatch(new GetTurbogenPowerTurbines(undefined)); // TurbogenPowerTurbinesState
    this.store.dispatch(new GetTurbogenHourlyPerformanceDrift(undefined)); // TurbogenHourlyPerformanceDriftState
    this.store.dispatch(new GetTurbogenHourlyPowerTurbines(undefined)); // TurbogenHourlyPowerTurbinesState
    this.store.dispatch(new GetSeaWaterLiftCrossPlot(undefined)); // SeaWaterLiftCrossPlotState
    this.store.dispatch(new GetWaterInjectionCrossPlot(undefined)); // WaterInjectionCrossPlotState
    this.store.dispatch(new GetWaterInjectionPerformanceDrift(undefined)); // WaterInjectionPerformanceDriftState
    this.store.dispatch(new GetSeaWaterLiftPerformanceDrift(undefined)); // SeaWaterLiftPerformanceDriftState
    this.store.dispatch(new PumpFeedPerformanceDrift(undefined)); // PumpPerformanceDriftState
    this.store.dispatch(new GetWaterInjectionHourlyPerformanceDrift(undefined)); // WaterInjectionHourlyPerformanceDriftState
    this.store.dispatch(new GetSeaWaterLiftHourlyPerformanceDrift(undefined)); // SeaWaterLiftHourlyPerformanceDriftState
    this.store.dispatch(new GetPumpHourlyPerformanceDrift(undefined)); // PumpHourlyPerformanceDriftState
    this.store.dispatch(new PumpFeedCrossPlot(undefined)); // PumpCrossPlotState
    this.store.dispatch(new GetActiveFpsoConfig('')); // ActiveFpsoConfigState
  }

  onChartClick(event: any, crossPlotBaselineParams: any[], crossPlotGraph: any, crossPlotMetadata: CrossPlotMetadata): any {
    if (![ 'Normal consumption', 'Over consumption', 'Under consumption', 'Last observation' ].includes(event.seriesName)) return;

    const graphIndex = crossPlotGraph.length > 1 ? crossPlotGraph.findIndex((crossPlot: any) => crossPlot.title.text === event.train) : 0;

    const pointCoord = event.value;

    const baselineParams = crossPlotBaselineParams.length > 1 ? crossPlotBaselineParams.find(params => params.train === event.train)
      : crossPlotBaselineParams[0];

    // Compute vertical intersection with baseline
    const crossPlotGraphElement = crossPlotGraph.length > 0 ? crossPlotGraph[graphIndex] : crossPlotGraph;
    let baselineCoord = crossPlotGraphElement.series[1].data.find((data: number[]) => data[0] === pointCoord[0]);
    if (!baselineCoord) {
      const baselineCoordX = pointCoord[0];
      const baselineParamsItem = baselineParams.length > 0 ? baselineParams[0] : baselineParams;
      const baselineCoordY = (baselineCoordX * baselineParamsItem.slope) + baselineParamsItem.intercept;
      baselineCoord = [ baselineCoordX, baselineCoordY ];

      if (!this.isPointNearBaseline(baselineParams, pointCoord[0])) {
        baselineCoord = [ baselineCoordX, 0 ];
      }
    }

    // adjust span position depending on point position
    let spanPosition = 'top';
    if (this.isPointNearBaseline(baselineParams, pointCoord[0])) {
      const distanceToYmax = baselineParams.ymax - pointCoord[1];
      const distanceToYmin = pointCoord[1] - baselineParams.ymin;
      if (distanceToYmax < distanceToYmin) {
        spanPosition = 'bottom';
      }
    }

    const markLineColor = event.seriesName === 'Over consumption' ? '#D10000' : '#40A900';
    const markAreaColor = event.seriesName === 'Over consumption' ? 'rgba(209, 0, 0, 0.1)' : 'rgba(64, 169, 0, 0.1)';
    const gapColor = event.seriesName === 'Over consumption' ? '#FC5555' : '#29CC6A';
    const gasSpan = ((pointCoord[1] - baselineCoord[1]) / baselineCoord[1]) * 100;

    const markLine = {
      silent: true,
      lineStyle: {
        type: 'solid',
        color: markLineColor
      },
      data: [
        [
          {
            coord: pointCoord
          },
          {
            coord: baselineCoord
          }
        ]
      ]
    };

    const markArea = {
      silent: true,
      label: {
        show: true,
        backgroundColor: 'white',
        color: 'black',
        padding: [ 16, 10 ],
        borderRadius: 8,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffsetX: 1,
        shadowOffsetY: 4,
        shadowBlur: 10,
        distance: 10,
        position: spanPosition,
        z: 3,
        formatter: crossPlotSpanFormatter(gasSpan.toFixed(2), baselineCoord[1], crossPlotMetadata),
        rich: {
          values: {
            fontWeight: 700
          },
          gapValue: {
            fontWeight: 700,
            color: gapColor
          }
        },
      },
      itemStyle: {
        color: markAreaColor,
        borderWidth: 1,
        borderColor: markLineColor
      },
      data: [
        [
          {
            yAxis: pointCoord[1],
          },
          {
            xAxis: baselineCoord[0],
            yAxis: baselineCoord[1]
          }
        ]
      ]
    };

    crossPlotGraphElement.id += 1;

    crossPlotGraphElement.series[4].markLine = markLine;
    crossPlotGraphElement.series[4].markArea = markArea;
    return crossPlotGraph.length >= 1 ? [ ...crossPlotGraph ] : { ...crossPlotGraph };
  }

  private isPointNearBaseline(baselineParams: any, baselineCoordX: number) {
    return baselineCoordX > baselineParams.xmin && baselineCoordX < baselineParams.xmax;
  }

  filterOperatingPoints(datePick: DatePicker, startDatePicker: Date | string, endDatePicker: Date | string, fullOperatingPoints: any[], crossPlotGraph: any, filteredOperatingPoints: any[]): any {
    const newStartDatePicker = datePick.startDate ? datePick.startDate : startDatePicker;
    const newEndDatePicker = datePick.endDate ? datePick.endDate : endDatePicker;

    const fullSortedData = fullOperatingPoints.sort((pointA, pointB) => new Date(pointA[0]).getTime() - new Date(pointB[0]).getTime());

    // Reset initial points
    crossPlotGraph.series[0].data = [];

    filteredOperatingPoints = fullSortedData.filter(point => {
      const date = new Date(point[0]);
      const start = newStartDatePicker ? newStartDatePicker : null;
      const end = newEndDatePicker ? newEndDatePicker : null;

      return (!start || date >= start) && (!end || date <= end);
    });

    crossPlotGraph.series[0].data = [];
    crossPlotGraph.series[0].data = filteredOperatingPoints.map(([ date, valueX, valueY ]) => [ valueX, valueY ]);
    crossPlotGraph.series[0].tooltip.formatter = tooltipDatesFormatter(filteredOperatingPoints.map(([ date ]) => date));
    return { ...crossPlotGraph };
  }

  filterOperatingPointsDrift(datePick: DatePicker, startDatePicker: Date | string, endDatePicker: Date | string, fullOperatingPoints: any[][], perfDrift: any): any {
    const newStartDatePicker = datePick.startDate ? datePick.startDate : startDatePicker;
    const newEndDatePicker = datePick.endDate ? datePick.endDate : endDatePicker;

    const filteredOperatingPoints = fullOperatingPoints.map(a => a.filter(point => {
      const date = new Date(point[0]);
      const start = newStartDatePicker ? newStartDatePicker : null;
      const end = newEndDatePicker ? newEndDatePicker : null;

      return (!start || date >= start) && (!end || date <= end);
    }));

    perfDrift.series = perfDrift.series || [];
    perfDrift.series.forEach((series: any, index: number) => {
      // Ensure each series and its data are initialized
      series = series || {};
      series.data = series.data || [];

      // Extracting and mapping data for each series
      perfDrift.series[index].data = filteredOperatingPoints[index].map(([ date, valueX ]) => [ date, valueX ]);

      // Initialize or update tooltip properties for each series
      series.tooltip = series.tooltip || {};
      series.tooltip.formatter = tooltipDatesFormatter(filteredOperatingPoints[index].map(([ date ]) => date));
    });
    return { ...perfDrift };
  }

  updateCrossPlotGraphs(datas: CrossPlot[], datePick: DatePick, startDatePicker: Date | string, endDatePicker: Date | string,
                        filteredOperatingPoints: any[], baselineValue: string) {
    if (!Array.isArray(datas) || datas.length == 0) {
      return;
    } else if (datas.length == 1) {
      return this.updateCrossPlotGraph(datas[0], datePick, startDatePicker, endDatePicker, filteredOperatingPoints, baselineValue);
    } else {
      let crossPlotGraph: any = CROSS_PLOT_MULTIPLE;
      this.setCrossplotMetadata(datas[0], crossPlotGraph);
      const baselineNames = datas.map((data, i) => data.equipmentName);
      crossPlotGraph.legend.data = baselineNames;
      crossPlotGraph.series = baselineNames.map((name, i) => buildBaselineModel(name, i));

      crossPlotGraph.series.data = [];
      datas.forEach((data, dataIndex) => {
        const baseline: any[] = [];

        data.baseline.xobserved.forEach((x, index) => {
          if (x >= data.baseline.xmin && x <= data.baseline.xmax) {
            baseline.push([ x, data.baseline.ymean[index] ]);
          }
          crossPlotGraph.series[dataIndex].data = baseline;
          crossPlotGraph.series[dataIndex].tooltip.formatter = crossplotTooltipFormatter(data.crossplotMetadata);
        });
      });
      return {
        crossPlotGraph: crossPlotGraph
      };
    }
  }

  updateCrossPlotGraph(data: CrossPlot, datePick: DatePick, startDatePicker: Date | string, endDatePicker: Date | string,
                       filteredOperatingPoints: any[], baselineValue: string): any {
    if (!Object.keys(data).length) return;
    let fullOperatingPoints: any[] = [];
    // Set default EChartsOption object
    let crossPlotGraph: any = cloneObject(CROSS_PLOT);

    //Set metadata configuration
    this.setCrossplotMetadata(data, crossPlotGraph);

    // Set crossPlotBaselineParams parameters
    let crossPlotBaselineParams = [ {
      train: 'Train ' + data.train,
      xmin: data.baseline.xmin,
      xmax: data.baseline.xmax,
      ymin: data.baseline.ymin,
      ymax: data.baseline.ymax,
      intercept: data.baseline.intercept,
      slope: data.baseline.slope,
    } ];

    const baseline: any[] = [];
    const baselineMin: any[] = [];
    const baselineMax: any[] = [];

    data.baseline.xobserved.forEach((x, index) => {
      if (x >= data.baseline.xmin && x <= data.baseline.xmax) {
        baseline.push([ x, data.baseline.ymean[index] ]);
        baselineMin.push([ x, data.baseline.ylower[index] ]);
        baselineMax.push([ x, data.baseline.yupper[index] ]);
      }
      fullOperatingPoints.push([ data.baseline.dates[index], x, data.baseline.yobserved[index] ]);
    });
    crossPlotGraph.series.data = []
    crossPlotGraph = this.filterOperatingPoints(datePick, startDatePicker, endDatePicker, fullOperatingPoints,
      crossPlotGraph, filteredOperatingPoints);

    crossPlotGraph.id = 1;
    crossPlotGraph.series[1].data = baseline;
    crossPlotGraph.series[2].data = baselineMax;
    crossPlotGraph.series[3].data = baselineMin;

    crossPlotGraph.series[4].data = data.lastObservations.normalConsumption.observations;
    crossPlotGraph.series[5].data = data.lastObservations.overConsumption.observations;
    crossPlotGraph.series[6].data = data.lastObservations.underConsumption.observations;

    crossPlotGraph.series[1].tooltip.formatter = crossplotTooltipFormatter(data.crossplotMetadata);
    crossPlotGraph.series[4].tooltip.formatter = tooltipDatesFormatter(data.lastObservations.normalConsumption.dates);
    crossPlotGraph.series[5].tooltip.formatter = tooltipDatesFormatter(data.lastObservations.overConsumption.dates);
    crossPlotGraph.series[6].tooltip.formatter = tooltipDatesFormatter(data.lastObservations.underConsumption.dates);

    if (data.lastObservations.latestOperatingPoint) {
      crossPlotGraph.series[7].data = data.lastObservations.latestOperatingPoint.observations;
      crossPlotGraph.series[7].tooltip.formatter = tooltipDatesFormatter(data.lastObservations.latestOperatingPoint.dates);
    }

    if (data.additionalBaselines?.length) {
      data.additionalBaselines.forEach((baseline, index) => {

        const baselineData: any[] = [];
        const equipmentName = !baseline?.equipmentName ? '' : baseline.equipmentName
        const baselineName = `${ baselineValue } ${ equipmentName }`;
        baseline.xobserved.forEach((x, index) => {
          if (x >= baseline.xmin && x <= baseline.xmax) {
            baselineData.push([ x, baseline.ymean[index] ])
          }
        });
        crossPlotGraph.series.push(
          {
            name: baselineName,
            type: 'line',
            color: BASELINES_DEFAULT_COLORS[index + 1],
            smooth: true,
            z: 0,
            silent: true,
            symbol: 'line',
            data: baselineData,
            progressiveThreshold: 10000,
          }
        );
        crossPlotGraph.legend.data.push(baselineName);
        crossPlotGraph.legend.selected[baselineName] = false;
      });
    }

    if (!data.train) crossPlotGraph.grid.bottom = LONG_LEGEND_BOTTOM;
    return {
      crossPlotGraph: crossPlotGraph,
      crossPlotBaselineParams: crossPlotBaselineParams,
      fullOperatingPoints: fullOperatingPoints
    };
  }

  private setCrossplotMetadata(data: CrossPlot, crossPlotGraph: any) {
    let xLabel = data.crossplotMetadata?.xAxisProperties?.label || 'Default xAxisLabel';
    let xUnit = data.crossplotMetadata?.xAxisProperties?.unit || 'Default xAxisUnit';
    let yLabel = data.crossplotMetadata?.yAxisProperties?.label || 'Default yAxisLabel';
    let yUnit = data.crossplotMetadata?.yAxisProperties?.unit || 'Default yAxisUnit';

    crossPlotGraph.xAxis.name = `${ xLabel } (${ xUnit })`;
    crossPlotGraph.yAxis.name = `${ yLabel } (${ yUnit })`;

  }

  public getCompressorEquipmentNames(allEquipment: string[], equipmentsByType: Map<string, string[]>): string[] {
    const compressorsType = this.store.selectSnapshot(ActiveCompressorsTypeState);
    return compressorsType
    !== OVERVIEW ? equipmentsByType.get(compressorsType) ?? allEquipment : allEquipment;
  }

  public getTurbogenEquipmentNames(allEquipment: string[]): string[] {
    return this.store.selectSnapshot(ActiveTurbineTabState)
    !== OVERVIEW ? [ this.store.selectSnapshot(ActiveTurbineTabState) ] : allEquipment;
  }

  public getTurbogenEquipmentName() {
    return this.store.selectSnapshot(ActiveTurbineTabState)
    !== OVERVIEW ? this.store.selectSnapshot(ActiveTurbineTabState) : null;
  }

  public getPumpEquipmentName() {
    return this.store.selectSnapshot(ActivePumpEquipmentNameState)
    !== OVERVIEW ? this.store.selectSnapshot(ActivePumpEquipmentNameState) : null;
  }

  public getPumpEquipmentNames(allEquipment: string[]): string[] {
    return this.store.selectSnapshot(ActivePumpEquipmentNameState)
    !== OVERVIEW ? [ this.store.selectSnapshot(ActivePumpEquipmentNameState) ] : allEquipment;
  }
}
