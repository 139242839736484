import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, skip, Subscription } from 'rxjs';
import { AppSandbox } from '../../app.sandbox';
import {
  BarChart,
  CrossPlot,
  CrossPlotMetadata,
  DatePick,
  getGraphMetaDataFrom,
  LAST_3_DAYS_GRAPH_TITLE,
  LAST_3_YEARS_GRAPH_TITLE,
  LinearChart,
  SHARED_LINE_SERIE_CONFIG,
  TabItem,
  TURBOGENERATORS_SYSTEM_GRAPH_NAME,
  TurbogenPowerReserve,
  ValueSeries, TableHeader, DataType, DatePicker,
} from '../../types';
import {
  CO2_FACTOR_GRAPH,
  POWER_RESERVE_GRAPH,
  POWER_TURBINES_GRAPH,
  DETAILS_TURBOGEN_KPI_HEADERS,
  GLOBAL_TURBOGEN_KPI_HEADERS,
  generateColors,
  generateTurbinesSeries
} from './turbogenerators.const';
import { ActivatedRoute, Router } from '@angular/router';
import { AppInsightsService, PendingRequestsService } from '../../services';
import {
  DateTimePickerProperties,
  getDatePickerProperties
} from "../../utils/date-time-picker/date-time-picker";
import { CommonGraphsService } from "../../services/common-graphs/common-graphs.service";
import { getStartDateSince3Days, getStartDateSince3Years } from "../../utils/date-time/date-time";
import { EChartsOption } from "echarts";
import { combineObservables } from "../../utils/observables/observables-tools";
import { DatePipe } from '@angular/common';
import { tooltipFormatter } from '../../utils';
import { cloneObject } from "../../utils/object-manipulation/object-manipulation";
import { EquipmentResponse } from '../../types/front-config.interface';
import { adjustMinMaxYAxisGraphsForPerformanceDrift } from "../../utils/graph/graph";
import { KpiOverview, KpiOverviewByEquipment } from "../../types/kpi-overview.interface";
import { ActiveFpsoState } from "../../../statemanagement";
import { Store } from "@ngxs/store";
import { formatLastDateInKpiEquipments } from "../../utils/overview-kpis/overview-kpis.utils";

@Component({
  selector: 'co2-turbogenerators',
  templateUrl: './turbogenerators.container.html',
})
export class TurbogeneratorsContainer implements OnInit, OnDestroy {
  readonly OVERVIEW = 'overview';
  readonly TURBOGEN_TYPE = "TURBOGEN";
  selectedTab!: string;
  subTabs!: TabItem[];
  selectedEquipment!: EquipmentResponse;
  allEquipmentNames!: string[];

  crossPlotTitle!: string;
  kpiTitle!: string | null;


  turbogenCo2Factor$: Observable<ValueSeries[]> = this.sb.turbogenCo2FactorData$;
  turbogenCo2FactorLoader$: Observable<boolean> = this.sb.turbogenCo2FactorDataLoader$;
  turbogenCo2FactorError$: Observable<boolean> = this.sb.turbogenCo2FactorDataError$;
  co2FactorGraph!: LinearChart | any;

  turbogenPowerReserve$: Observable<TurbogenPowerReserve> = this.sb.turbogenPowerReserve$;
  turbogenPowerReserveLoader$: Observable<boolean> = this.sb.turbogenPowerReserveLoader$;
  turbogenPowerReserveError$: Observable<boolean> = this.sb.turbogenPowerReserveError$;

  turbogenOverviewKpis$: Observable<KpiOverview> = this.sb.turbogenOverviewKpis$;

  globalHeaders!: TableHeader[];
  globalKPIValues!: KpiOverview | undefined;
  detailsKPIValues!: Array<KpiOverviewByEquipment> | undefined;
  lastDate: string | undefined;
  globalDisplayedHeaders!: string[];
  detailsHeaders: TableHeader[] = [ ...DETAILS_TURBOGEN_KPI_HEADERS ];
  detailsDisplayedHeaders: string[] = this.detailsHeaders.map((header) => header.id);

  powerReserveGraph!: BarChart;


  fpsoConfigs!: string[];
  activeFpsoConfig$: Observable<string> = this.sb.activeFpsoConfig$;

  activeTurbineTab$: Observable<string> = this.sb.activeTurbineTab$;

  turbogenCrossPlotData$: Observable<CrossPlot[]> = this.sb.turbogenCrossPlot$;
  turbogenCrossPlotLoader$: Observable<boolean> = this.sb.turbogenCrossPlotLoader$;
  turbogenCrossPlotError$: Observable<boolean> = this.sb.turbogenCrossPlotError$;
  crossPlotGraph!: any;
  crossPlotBaselineParams: any[] = [];
  turboGeno2FactorGraph!: ValueSeries[];

  performanceDriftData$: Observable<ValueSeries[]> = this.sb.turbogenPerformanceDrift$.pipe(skip(1));
  performanceDriftTurboGraph: EChartsOption[] = [];

  hourlyPerformanceDriftData$: Observable<ValueSeries[]> = this.sb.turbogenHourlyPerformanceDrift$.pipe(skip(1));

  combinedPerformanceDriftLoader$: Observable<boolean>
    = combineObservables([ this.sb.turbogenPerformanceDriftLoader$,
    this.sb.turbogenHourlyPerformanceDriftLoader$ ], true);
  combinedPerformanceDriftError$: Observable<boolean>
    = combineObservables<boolean>([ this.sb.turbogenPerformanceDriftError$,
    this.sb.turbogenHourlyPerformanceDriftError$ ], true);

  turbogenPowerTurbines$: Observable<ValueSeries> = this.sb.turbogenPowerTurbines$.pipe(skip(1));
  turbogenPowerTurbinesLoader$: Observable<boolean> = this.sb.turbogenPowerTurbinesLoader$;
  turbogenPowerTurbinesError$: Observable<boolean> = this.sb.turbogenPowerTurbinesError$;
  powerTurbinesGraph: any[] = [];

  turbogenHourlyPowerTurbines$: Observable<ValueSeries> = this.sb.turbogenHourlyPowerTurbines$.pipe(skip(1));

  subscriptions: Subscription[] = [];
  getSelectedEquipmentSubscribe!: Subscription;
  getSubTabsSubscribe!: Subscription;
  activatedRouteSubscription!: Subscription;

  datePickerProperties!: DateTimePickerProperties;
  lastDatePick: DatePicker = {};
  actualDate = new Date();
  fullOperatingPoints: any[] = [];
  filteredOperatingPoints: any[] = [];
  powerTurbinesColors: string[] = [];

  private readonly _powerGraphsTitles =
    {
      firstTitle: LAST_3_YEARS_GRAPH_TITLE,
      secondTitle: LAST_3_DAYS_GRAPH_TITLE
    };

  protected readonly getStartDateSince3Years = getStartDateSince3Years;
  protected readonly getStartDateSince3Days = getStartDateSince3Days;
  crossPlotMetadata !: CrossPlotMetadata;
  fullDriftPoints: any[] = [];
  fpso: string = '';
  fpsoWithSensors: boolean = true;

  constructor(
    public sb: AppSandbox,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pendingRequestsService: PendingRequestsService,
    private appInsightsService: AppInsightsService,
    private graphsService: CommonGraphsService,
    private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.appInsightsService.logPageView("turbines");
    this.datePickerProperties = getDatePickerProperties(this.actualDate);
    this.fpso = this.store.selectSnapshot(ActiveFpsoState);
    this.fpsoWithSensors = this.fpso !== 'PAZFLOR';

    this.activatedRouteSubscription = this.activatedRoute.params.subscribe(params => {
      this.cleanGraphs();
      this.selectedTab = params['turbine-tab'];
      this.sb.setActiveTurbineTab(this.selectedTab);

      this.getSelectedEquipmentSubscribe = this.sb.getSelectedEquipment(this.TURBOGEN_TYPE).subscribe(equipment => this.onSelectEquipment(equipment));

      this.getSubTabsSubscribe = this.sb.getSubTabs(this.TURBOGEN_TYPE).subscribe(tabs => this.onGetTabs(tabs));
    });
  }

  private onSelectEquipment(equipment: EquipmentResponse) {
    this.selectedEquipment = equipment;
    this.crossPlotTitle = `${ equipment.label } ${ this.fpsoWithSensors ? 'Equipments' : ''} Baselines`;
    this.kpiTitle = `${ equipment.label } Overview`;
    this.globalHeaders = [ {
      id: 'runningEquipmentsCount',
      label: `Running ${ equipment.labelAbbreviation }`,
      dataType: DataType.TEXT,
    }, ...GLOBAL_TURBOGEN_KPI_HEADERS ];

    this.globalDisplayedHeaders = this.globalHeaders.map((header) => header.id);

    this.fpsoConfigs = getGraphMetaDataFrom(this.selectedEquipment.graphMetadata, 'TURBINES_POWER_RESERVES_TYPES');
    if (this.fpsoConfigs?.length) {
      this.sb.setActiveFpsoConfig(this.fpsoConfigs[0]);
    }

    this.subscriptions = [
      this.turbogenOverviewKpis$.subscribe(data => {
        if (!data?.kpiByEquipments || data.kpiByEquipments.length === 0) return;
        this.globalKPIValues = data;
        this.detailsKPIValues = data.kpiByEquipments;
        this.lastDate = formatLastDateInKpiEquipments(data.kpiByEquipments);
      }),
      this.turbogenPowerReserve$.subscribe(
        (data: TurbogenPowerReserve) => this.setPowerReserveGraphData(data)
      ),
      this.turbogenCo2Factor$.subscribe(
        (data: ValueSeries[]) => {
          if (data.length > 0) {
            this.turboGeno2FactorGraph = data;
            this.updateGraphInputs(data)
          }
        }
      ),
      this.turbogenCrossPlotData$.subscribe(
        (crossPlots: CrossPlot[]) => {
          if (crossPlots?.length > 0) {
            this.crossPlotMetadata = crossPlots[0]?.crossplotMetadata;
            let graphData = this.sb.updateCrossPlotGraphs(crossPlots, {
                type: 'startDate',
                value: this.datePickerProperties.startDatePicker
              },
              this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.filteredOperatingPoints, 'Baseline Turbogenerator ')
            if (graphData) {
              this.crossPlotGraph = graphData.crossPlotGraph;
              this.crossPlotBaselineParams = graphData.crossPlotBaselineParams;
              this.fullOperatingPoints = graphData.fullOperatingPoints;
            }
          }
        }
      ),
      this.performanceDriftData$.subscribe(
        (data: ValueSeries[]) => {
          if (data) {
            this.performanceDriftTurboGraph =
              this.graphsService.updateManyEquipmentsPerformanceDriftGraphs(data, LAST_3_YEARS_GRAPH_TITLE, this.performanceDriftTurboGraph, TURBOGENERATORS_SYSTEM_GRAPH_NAME, this.selectedEquipment.graphMetadata);
            // Now TypeScript knows that this.performanceDriftGraph is an array
            // @ts-ignore
            this.fullDriftPoints = this.performanceDriftTurboGraph[0].series.map((s => s.data));
            this.performanceDriftTurboGraph[0] = this.sb.filterOperatingPointsDrift({},
              this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullDriftPoints, this.performanceDriftTurboGraph[0]);
            this.performanceDriftTurboGraph = adjustMinMaxYAxisGraphsForPerformanceDrift(this.performanceDriftTurboGraph);
          }
        }
      ),
      this.hourlyPerformanceDriftData$.subscribe(
        (data: ValueSeries[]) => {
          if (data) {
            this.performanceDriftTurboGraph =
              this.graphsService.updateManyEquipmentsPerformanceDriftGraphs(data, LAST_3_DAYS_GRAPH_TITLE, this.performanceDriftTurboGraph, TURBOGENERATORS_SYSTEM_GRAPH_NAME, this.selectedEquipment.graphMetadata);
            this.performanceDriftTurboGraph = adjustMinMaxYAxisGraphsForPerformanceDrift(this.performanceDriftTurboGraph);
          }
        }
      ),
      this.turbogenPowerTurbines$.subscribe(
        (data: ValueSeries) => this.updatePowerTurbinesGraph(data, LAST_3_YEARS_GRAPH_TITLE)
      ),
      this.turbogenHourlyPowerTurbines$.subscribe(
        (data: ValueSeries) => this.updatePowerTurbinesGraph(data, LAST_3_DAYS_GRAPH_TITLE)
      )
    ];
  }

  private onGetTabs(tabs: TabItem[]) {
    this.subTabs = [ {
      id: this.OVERVIEW,
      label: this.selectedEquipment.label
    }, ...tabs ];
    this.allEquipmentNames = this.subTabs.filter(i => i.id !== this.OVERVIEW).map(i => i.id);

    this.pendingRequestsService.cancelAllPendingRequests();
    this.loadPageData(this.selectedTab);
  }

  public loadPageData(activeTab: string): void {
    const isOverviewTab = activeTab === this.OVERVIEW;

    this.sb.loadTurbogenCrossPlot(this.fpsoWithSensors ? this.allEquipmentNames : []);

    if (this.fpsoWithSensors) {
      this.sb.loadTurbogenPerformanceDrift(this.TURBOGEN_TYPE, getStartDateSince3Years(), this.allEquipmentNames);
      this.sb.loadTurbogenHourlyPerformanceDrift(this.TURBOGEN_TYPE, this.allEquipmentNames);
    }

    if (isOverviewTab) {
      if (this.fpsoWithSensors) this.sb.loadTurbogenCo2FactorData();
      this.sb.loadTurbogenPowerReserve();
      this.sb.loadTurbogenPowerTurbines(getStartDateSince3Years());
      this.sb.loadTurbogenHourlyPowerTurbines(getStartDateSince3Days());
      this.sb.loadTurbogenOverviewKpis(this.TURBOGEN_TYPE, this.allEquipmentNames);
    }
  }

  private setPowerReserveGraphData(data: TurbogenPowerReserve): void {
    if (data) {
      this.powerReserveGraph = cloneObject(POWER_RESERVE_GRAPH);
      this.powerReserveGraph.series[0].data = [ data.powerLoadPercentage ];
      this.powerReserveGraph.series[1].data = [ data.powerReservePercentage ];

      if (this.powerReserveGraph.series[0].label &&
        this.powerReserveGraph.series[1].label) {
        this.powerReserveGraph.series[0].label.formatter = 'Power Gen Load ' + data.powerLoadPercentage + '%\n{subInfo|(eq. ' + data.powerLoad + ' MW)}';
        this.powerReserveGraph.series[1].label.formatter = 'Power Reserve ' + data.powerReservePercentage + '%\n{subInfo|(eq. ' + data.powerReserve + ' MW)}';
      }

      // Avoid labels overlap when both percentages are 0
      if (data.powerLoadPercentage === 0 && data.powerReservePercentage === 0)
        this.powerReserveGraph.series.forEach(serie => serie.stack = undefined);
    }
  }

  onFpsoConfigChange(fpsoConfig: string): void {
    this.sb.setActiveFpsoConfig(fpsoConfig);
    this.sb.loadTurbogenPowerReserve();
  }

  private updateGraphInputs(data: ValueSeries[]): void {
    this.co2FactorGraph = cloneObject(CO2_FACTOR_GRAPH);
    this.co2FactorGraph.yAxis.axisLabel = {
      color: '#666666', formatter: (value: number) => {
        return this.formatNumber(value)
      }
    };
    data.forEach((turbine: ValueSeries) => {
      const equipmentConfig = getGraphMetaDataFrom(this.selectedEquipment.graphMetadata, turbine.name);
      if (!equipmentConfig) return;
      this.co2FactorGraph.tooltip.formatter = tooltipFormatter();
      this.co2FactorGraph.series.push({
        name: turbine.name,
        ...SHARED_LINE_SERIE_CONFIG,
        ...equipmentConfig,
        data: turbine.data,
        symbol: 'line',
      });
    });
  }

  formatDate(dateString: string): string | null {
    const date = new Date(dateString);
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    if (regex.test(dateString)) {
      const parsedDate = new Date(dateString);
      return this.datePipe.transform(parsedDate, 'dd/MM/yyyy HH:mm:ss');
    } else {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
      return `${ day }/${ month }/${ year }`;
    }
  }

  formatDataDates(data: any): any {
    return data.map((item: any) => ([
      this.formatDate(item[0]),
      item[1],
      item[2]
    ]));
  }

  formatNumber(num: number): string {
    let str = num.toString();
    return str.replace(',', '');
  }

  private updatePowerTurbinesGraph(data: ValueSeries, title: string): void {
    if (!data || !Object.keys(data).length) return;

    const maxTurbineRunning = Math.max(...data.data.map(couple => couple[2] ?? -Infinity));
    const numSeries: number = maxTurbineRunning + 1;
    const colors: string[] = generateColors(numSeries);
    this.powerTurbinesColors = this.powerTurbinesColors.length < numSeries ? this.powerTurbinesColors.concat(colors.slice(this.powerTurbinesColors.length)) : this.powerTurbinesColors;

    const graph = cloneObject(POWER_TURBINES_GRAPH);
    let series = generateTurbinesSeries(this.powerTurbinesColors)
    graph.legend.data = series.map(serie => serie.name);
    graph.series = [
      ...series,
      {
        ...SHARED_LINE_SERIE_CONFIG,
        name: data.name,
        data: this.formatDataDates(data.data),
        areaStyle: {
          opacity: 0.2
        }
      }
    ];
    graph.title.text = title;
    graph.yAxis.axisLabel = { color: '#666666', formatter: (value: number) => this.formatNumber(value) };
    graph.legend.show = title !== LAST_3_DAYS_GRAPH_TITLE;

    this.powerTurbinesGraph.push(graph);
    const pieces = this.createPieces(data);
    this.assignPiecesToGraphs(pieces);
    if (this.powerTurbinesGraph.length == 2) {
      this.powerTurbinesGraph = this.graphsService
        .reorderTwoGraphsByTitleText(
          this.powerTurbinesGraph,
          this._powerGraphsTitles.firstTitle,
          this._powerGraphsTitles.secondTitle)
    }
  }

  private createPieces(data: ValueSeries): any[] {
    if (!data.data[0]) return [];

    let pieces: any[] = [];
    let startIndex = 0;
    let currentValue: number = data.data[0][2] ?? 0;

    data.data.forEach((value: any[], index: number) => {
      if (index === 0) return;
      if (value[2] !== currentValue || index === data.data.length - 1) {
        pieces.push({
          gte: startIndex,
          lt: index,
          color: this.powerTurbinesColors[currentValue]
        });
        currentValue = value[2];
        startIndex = index;
      }
    });

    return pieces;
  }

  private assignPiecesToGraphs(pieces: any[]): void {
    this.powerTurbinesGraph.forEach(graph => {
      if (this.powerTurbinesGraph.length !== 2 || graph.title.text == LAST_3_YEARS_GRAPH_TITLE) {
        graph.visualMap.pieces = pieces;
      }
    });
  }

  onSelectTab(turbine: string): void {
    this.cleanGraphs();
    const affiliate = this.sb.getActiveAffiliate();
    const fpso = this.sb.getActiveFpso();
    this.router.navigate([ `/${ affiliate }/${ fpso }/power-generation/${ turbine }` ]);
  }

  filterDatePick(datePick: DatePick): void {
    this.lastDatePick = datePick;
    this.crossPlotGraph = this.sb.filterOperatingPoints(datePick,
      this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullOperatingPoints, this.crossPlotGraph,
      this.filteredOperatingPoints);
  }

  onChartClick(event: any): void {
    this.crossPlotGraph = this.sb.onChartClick(event, this.crossPlotBaselineParams, this.crossPlotGraph, this.crossPlotMetadata) ||
      this.crossPlotGraph;
  }

  filterDatePickDrift(datePick: DatePick): void {

    this.performanceDriftTurboGraph[0] = this.sb.filterOperatingPointsDrift(datePick,
      this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullDriftPoints, this.performanceDriftTurboGraph[0]);
    this.performanceDriftTurboGraph = adjustMinMaxYAxisGraphsForPerformanceDrift(this.performanceDriftTurboGraph);
  }

  private cleanGraphs() {
    this.performanceDriftTurboGraph = [];
    this.powerTurbinesGraph = [];
    this.crossPlotGraph = [];
    this.co2FactorGraph = [];

    this.sb.resetAllGraphsStates();
    this.cleanSubscriptions();
  }

  private cleanSubscriptions() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.getSelectedEquipmentSubscribe) {
      this.getSelectedEquipmentSubscribe.unsubscribe();
    }
    if (this.getSubTabsSubscribe) {
      this.getSubTabsSubscribe.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.activatedRouteSubscription.unsubscribe();
    this.cleanSubscriptions();
  }

  showAllDataForCrossplot(show: boolean) {
    const largePeriod = {startDate: new Date(1999, 1, 1), endDate: new Date() };
    this.crossPlotGraph = this.sb.filterOperatingPoints(show ? largePeriod : this.lastDatePick,
      this.datePickerProperties.startDatePicker, this.datePickerProperties.endDatePicker, this.fullOperatingPoints, this.crossPlotGraph,
      this.filteredOperatingPoints);
  }
}
