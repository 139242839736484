<div class="card alert-details">
  <div class="card-title">
    Details: {{ selectedAlert.systemType | replaceDashWithSpace }} {{ selectedAlert.equipmentName }}
    <span class="material-icons close" (click)="closePanel.emit()"> close </span>
  </div>

  <div class="card-body">
    <co2-loading-spinner>
      <!-- Avoidable emissions & Description -->
      <div class="indicators">
        <!-- Avoidable emissions -->
        <div *ngIf="!selectedAlert.observation.includes('warning')" class="avoidable-emissions">
          <div class="label">Avoidable emissions</div>
          <div class="value">{{ selectedAlert.avoidableEmissions }} ktCO2eq</div>
        </div>

        <!-- Description -->
        <div class="description">
          <div class="label">Description</div>
          <div class="value">{{ selectedAlert.observation }}</div>
        </div>
      </div>
      <div class="trend-graphs">
        <!-- Trend Graph -->
        <co2-ui-graph *ngIf="selectedAlert.trendGraph" [options]="selectedAlert.trendGraph" class="long-term">
        </co2-ui-graph>

        <!-- Hourly trend Graph -->
        <co2-ui-graph *ngIf="selectedAlert.hourlyTrendGraph" [options]="selectedAlert.hourlyTrendGraph"
        [noAvailableData]="noAvailableData" class="short-term">
        </co2-ui-graph>

      </div>
      <!-- Link to details graphs -->
      <div *ngIf="!selectedAlert.observation.includes('warning')" class="links">
        <a [routerLink]="['/', affiliate, fpso, 'settings', 'methodology']" target="_blank" rel="noopener noreferrer" class="link-primary arrow formula-link">Formula Details</a>
      <button type="button" class="btn btn-secondary" (click)="navigateToDetails.emit()">View more graphs
      </button>
    </div>
    </co2-loading-spinner>
  </div>
  <co2-discussions *ngIf="!selectedAlert.observation.includes('warning')" [selectedAlert]="selectedAlert" (updateSelectedAlert)="updateAlert()"></co2-discussions>
</div>
