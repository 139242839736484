<div class="card alert-table" [class.no-right-border-radius]="selectedAlert">
  <div class="card-title">
    <ul class="menu">
      <li class="alerts" [ngClass]="activeAlert ? 'border-active' : ''" id="alert" (keypress)="switchToAlerts()"
        (click)="switchToAlerts()">
        <div class="size" [ngClass]="activeAlert ? 'active' : ''">{{size}}</div>
        {{ size === 0 ? 'No Alerts' : ' Alerts' }}
        <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
          <span class="material-icons-outlined" aria-hidden="true">info</span>
        </button>
      </li>
      <li *ngIf="warningsTable" class="alerts" [ngClass]="!activeAlert ? 'border-active' : ''"
        (keypress)="switchToAlerts()" (click)="switchToWarnings()">
        <div class="size" [ngClass]="!activeAlert ? 'active' : ''">{{warningsTable.length}}</div>
        {{ warningsTable.length === 0 ? 'No Warnings' : ' Warnings' }}
        <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal1">
          <span class="material-icons-outlined" aria-hidden="true">info</span>
        </button>
      </li>
    </ul>

    <div *ngIf="activeAlert" class="alert-type d-flex">
      <div class="alert-filter d-flex">
        <input class="form-control" type="search" placeholder="Search" aria-label="Search" [(ngModel)]="alertFilter"
          (keydown)="onInputFilterChangeKeyDown($event)" (ngModelChange)="onInputFilterChange()">
        <button class="btn btn-outline-primary btn-circle" (click)="onFilterChange.emit(alertFilter)">
          <span class="material-icons-outlined">search</span>
        </button>
      </div>
      <co2-table-legend [legendList]="legendList" [selectedAlert]="selectedAlert"
        (legendClick)="legendClick.emit($event)">
      </co2-table-legend>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="observationsInfoModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="observationsInfoModalLabel">
            <span class="material-icons-outlined info-button-right" aria-hidden="true">info</span>Learn more - Alerts
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <co2-table [headers]="headers" class="observations-metadata-table" [rows]="metadataList"
            [displayedHeaders]="displayedModalHeaders" [disabled]="false">
          </co2-table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="observationsInfoModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title" id="observationsInfoModalLabel">
            <span class="material-icons-outlined info-button-right" aria-hidden="true">info</span>Learn more - Warnings
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{text}}
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <co2-loading-spinner [isLoading]="isLoading">

      <co2-table *ngIf="activeAlert" [headers]="alertHeaders" [rows]="alertsTable" [clickableRows]="true"
        (rowClick)="alertClick.emit($event)" [displayedHeaders]="displayedHeaders" [selectedRow]="selectedAlert"
        class="alert-table" [class.no-headers]="selectedAlert">
      </co2-table>
      <co2-table *ngIf="!activeAlert" [headers]="warningHeaders" [rows]="warningsTable" [clickableRows]="true"
        (rowClick)="alertClick.emit($event)" [displayedHeaders]="displayedWarningHeaders" [selectedRow]="selectedAlert"
        class="alert-table" [class.no-headers]="selectedAlert">
      </co2-table>
    </co2-loading-spinner>
  </div>
</div>