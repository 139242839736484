<div class="card-container">
  <div class="indicator">
    <span class="indicator__title">{{ indicator.indicatorName }}</span>
    <span class="material-icons-outlined" *ngIf="indicator.tooltip" [tooltip]="indicator.tooltip" [position]="TooltipPosition.ABOVE"  [theme]="TooltipTheme.LIGHT">info</span>
  </div>
  <ng-template #elseBlock>
    <span *ngIf="indicator.value; else noDataBlock" class="value" [ngClass]="{
      'percent': indicator.unit === 'ktCO2/yr',
      'under-target': indicator.target && indicator.value <= indicator.target,
      'over-target': indicator.target && indicator.value > indicator.target
    }">
      {{ parseFloat(indicator.value.toFixed(2)) }}
      <span class="unit">{{ indicator.unit }}</span>
    </span>
    <ng-template #noDataBlock>
      <span class="no-data-available">No data available</span>
    </ng-template>
  </ng-template>

  <div class="value-and-target">
    <span *ngIf="indicator.secondValue" class="value-second" [ngClass]="{'under-target': indicator.target && indicator.secondValue <= indicator.target,
    'over-target': indicator.target && indicator.secondValue > indicator.target}">
{{ parseFloat(indicator.secondValue.toFixed(2)) }}
<span class="unit">{{ indicator.secondUnit }}</span>
</span>
    <span *ngIf="indicator.target" class="target">
      Target:&nbsp;
      <span class="target-value">{{ indicator.target }}</span>
    </span>

    <span *ngIf="isInDevelopment; else elseBlock">
      <span class="value over-target" [ngClass]="{'percent': indicator.unit === 'ktCO2/yr'}">
        <span style="color: red; font-size: 10px"> (The value will be fixed soon) </span>
        <span class="unit">{{ indicator.unit }}</span>
      </span>
    </span>
  </div>
</div>
