import { Injectable } from '@angular/core';

import { PendingRequestsService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class CancelPendingRequestsGuard  {

  constructor(private pendingRequestsService: PendingRequestsService) { }

  canActivate(): boolean {
    this.pendingRequestsService.cancelAllPendingRequests();
    return true;
  }

}
