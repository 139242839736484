import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ClearAlertsData, GetAlertsData } from './actions';
import { Alert, Observations, PointCouple } from '../../app/types';
import {
  ALERT_TREND_GRAPH,
  defaultColor,
  systemColorMap
} from 'src/modules/app/containers/general-dashboard/general-dashboard.container.const';
import { tooltipFormatter } from "../../app/utils";
import { cloneObject } from "../../app/utils/object-manipulation/object-manipulation";
import { adjustMinMaxYAxisGraphsForAlerting, buildAlertGraph } from "../../app/utils/graph/graph";

@State<Observations>({
  name: 'alertData',
  defaults: { alerts: [], totalSize: 0 },
})
@Injectable()
export class AlertsDataState {

  constructor() {
  }

  @Action(GetAlertsData)
  getAlertsData(context: StateContext<Observations>, { payload, resetState }: GetAlertsData): void {
    const updatedPayload = payload;
    updatedPayload.alerts.forEach((alert: Alert) => {
      alert.nameWithType = alert.systemType === 'Compression'
      ? `<b>${alert.systemType} ${alert.equipmentType}<br>(${alert.equipmentName})</b>`
      : `<b>${alert.systemType} <b> ${alert.equipmentName}</b>`;

      alert.trendGraph = buildAlertGraph(alert.trendData, alert.systemType);
      alert.hourlyTrendGraph = buildAlertGraph(alert.hourlyTrendData, alert.systemType, true);

      adjustMinMaxYAxisGraphsForAlerting(alert, alert.trendData, alert.hourlyTrendData);
    });

    context.setState(
      {
        alerts: resetState ? updatedPayload.alerts : context.getState().alerts.concat(updatedPayload.alerts),
        totalSize: updatedPayload.totalSize
      }
    );
  }

  @Action(ClearAlertsData)
  clearAlertsData(context: StateContext<Observations>): void {
    context.setState({ alerts: [], totalSize: 0 } as Observations);
  }
}
