<div class="dropdown" #dropdownMenu>
  <button class="btn btn-primary dropdown-toggle" type="button" (click)="toggleDropdown()">
    {{ getCheckedLegendsCount() > 0 ? getCheckedLegendsCount() + ' alert type(s) are selected' : 'Select type of alert' }}
  </button>
  <div class="dropdown-menu" [class.show]="isDropdownOpen">
    <div *ngFor="let legend of legendList; let i = index" class="form-check">
      <input 
        type="checkbox" 
        class="form-check-input" 
        [id]="'legend-checkbox-' + i" 
        [checked]="legend.checked"
        (change)="legendClick.emit(legend)">
      
      <label 
        class="form-check-label"
        [for]="'legend-checkbox-' + i"
        (keypress)="legendClick.emit(legend)"
        [class.disabled]="legend.checked">
        {{legend.name}}
      </label>
    </div>
  </div>
</div>
