import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";

@Injectable()
export class CustomRequestInterceptor implements HttpInterceptor {
  private readonly apimSubscriptionKeyHeaderName = 'x-apif-apikey';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const updatedRequest = request.clone({
        headers: request.headers.set(this.apimSubscriptionKeyHeaderName, environment.apimSubscriptionKey)});
    return next.handle(updatedRequest);
  }
}
