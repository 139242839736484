import { TotalCo2YearlyState } from './total-co2-yearly';
import { ActiveCompressorsTypeState } from './active-compressors-type';
import { UserNameState } from './user-name';
import { CompressorsPowerDataState } from './compressors-power-data';
import { CompressorsAsvDataState } from './compressors-asv-data';
import { CompressorsPolytropicDataState } from './compressors-polytropic-data';
import { CompressorsOverviewKpisState } from './compressors-overview-kpis';
import { ActiveFpsoState } from './active-fpso';
import { TurbogenPowerReserveState } from './turbogen-power-reserve';
import { ActiveFpsoConfigState } from './active-fpso-config';
import { TurbogenCo2FactorState } from './turbogen-co2-factor-data';
import { ActiveTurbineTabState } from './active-turbine-tab';
import { TurbogenCrossPlotState } from './turbogen-cross-plot';
import { CompressorCrossPlotState } from './compressors-cross-plot';
import { TurbogenPerformanceDriftState } from './turbogen-performance-drift';
import { TurbogenPowerTurbinesState } from './turbogen-power-turbines';
import { TurbogenHourlyPerformanceDriftState } from './turbogen-hourly-performance-drift';
import { TurbogenHourlyPowerTurbinesState } from './turbogen-hourly-power-turbines';
import { AlertsDataState } from './alerts';
import { ActiveAffiliateState } from './active-affiliate';
import { SeaWaterLiftCrossPlotState } from "./sea-water-lift-cross-plot";
import { ActiveSeaWaterLiftTypeState } from "./active-sea-water-lift-type";
import { ActiveWaterInjectionTypeState } from "./active-water-injection-type";
import { WaterInjectionCrossPlotState } from "./water-injection-cross-plot";
import { ActivePumpEquipmentNameState } from "./active-pump-equipment-name";
import { PumpCrossPlotState } from "./pump-cross-plot";
import { WaterInjectionPerformanceDriftState } from "./water-injection-performance-drift";
import { SeaWaterLiftPerformanceDriftState } from "./sea-water-lift-performance-drift";
import { PumpPerformanceDriftState } from "./pump-performance-drift";
import { WaterInjectionHourlyPerformanceDriftState } from "./water-injection-hourly-performance-drift";
import { SeaWaterLiftHourlyPerformanceDriftState } from "./sea-water-lift-hourly-performance-drift";
import { PumpHourlyPerformanceDriftState } from "./pump-hourly-performance-drift";
import { TabsElementsState } from './tabs/state';
import { CompressorsHourlyPerformanceDriftState } from "./compressors-hourly-performance-drift";
import { CompressorsPerformanceDriftState } from "./compressors-performance-drift";
import { PumpOverviewKpisState } from "./pump-overview-kpis";
import { TotalAvoidedCo2State } from "./total-avoided-co2";
import { TurbogenOverviewKpisState } from "./turbogen-overview-kpis";
import { WarningssDataState } from './warnings';

export const globalState = [
  TotalCo2YearlyState,
  TotalAvoidedCo2State,
  ActiveCompressorsTypeState,
  UserNameState,
  CompressorsPowerDataState,
  CompressorsAsvDataState,
  CompressorsPolytropicDataState,
  CompressorsOverviewKpisState,
  PumpOverviewKpisState,
  TurbogenOverviewKpisState,
  CompressorsHourlyPerformanceDriftState,
  CompressorsPerformanceDriftState,
  ActiveFpsoState,
  TabsElementsState,
  TurbogenPowerReserveState,
  ActiveFpsoConfigState,
  TurbogenCo2FactorState,
  ActiveTurbineTabState,
  TurbogenCrossPlotState,
  CompressorCrossPlotState,
  TurbogenPerformanceDriftState,
  TurbogenPowerTurbinesState,
  TurbogenHourlyPerformanceDriftState,
  AlertsDataState,
  WarningssDataState,
  TurbogenHourlyPowerTurbinesState,
  ActiveAffiliateState,
  ActiveSeaWaterLiftTypeState,
  SeaWaterLiftCrossPlotState,
  ActiveWaterInjectionTypeState,
  WaterInjectionCrossPlotState,
  ActivePumpEquipmentNameState,
  PumpCrossPlotState,
  WaterInjectionPerformanceDriftState,
  SeaWaterLiftPerformanceDriftState,
  PumpPerformanceDriftState,
  WaterInjectionHourlyPerformanceDriftState,
  SeaWaterLiftHourlyPerformanceDriftState,
  PumpHourlyPerformanceDriftState
];
