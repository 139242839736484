<co2-overview-ghg-emissions [activeFpso]="activeFpso$ | async"
                            [totalCo2YearlyEmission]="totalCo2YearlyEmission$ | async"
                            [totalAvoidedCo2]="totalAvoidedCo2$ | async"
                            [energyEfficiency]="energyEfficiency"
                            [currentEmissions]="currentEmissions"
                            [lowestAchievableEmissions]="lowestAchievableEmissions"
                            [isLoading]="totalCo2YearlyEmissionLoader$ | async"
                            [hasLoadingError]="totalCo2YearlyEmissionError$ | async"
                            (reloadData)="sb.loadTotalCo2YearlyEmission()"
                            [sankey]="sankey"
>
</co2-overview-ghg-emissions>

<div class="alerts-card">
  <co2-alert-table [alertsTable]="allAlerts"
                   [warningsTable]="allWarnings"
                   [size]="totalSize"
                   [isLoading]="alertTableWithChartsLoader$ | async"
                   [hasLoadingError]="alertTableError$ | async"
                   (reloadData)="sb.loadAlerts(currentIndex, alertTypeSelected, alertFilter)"
                   [legendList]="alertTableLegends"
                   (legendClick)="onLegendClick($event)"
                   (alertClick)="updateSelectedAlert($event)"
                   (closeSidePanel)="updateSelectedAlert(undefined)"
                   [selectedAlert]="selectedAlert"
                   [alertHeaders]="alertHeaders"
                   [displayedHeaders]="displayedHeaders"
                   [warningHeaders]="warningHeaders"
                   [displayedWarningHeaders]="displayedWarningHeaders"
                   [alertFilter]="alertFilter"
                   (onFilterChange)="onFilterChange($event)">
  </co2-alert-table>

  <co2-side-panel *ngIf="selectedAlert" id="sidePanel"
                  [selectedAlert]="selectedAlert"
                  [noAvailableData]="noAvailableData"
                  (updateSelectedAlert)="updateSelectedAlertStatus()"
                  (closePanel)="updateSelectedAlert(undefined)"
                  (navigateToDetails)="navigateToDetails()"
                  [style.--borderColor]="getAlertStyle(selectedAlert.alertType).color"
                  [style.--backgroundColor]="getAlertStyle(selectedAlert.alertType).badgeBackgroundColor">
  </co2-side-panel>
</div>
