import { Component, OnInit } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { AuthService } from 'src/modules/app/services';
import { ActiveAffiliateState, ActiveFpsoState } from '../../../../../statemanagement';
import { Tab } from 'src/modules/app/types/tab.interface';
import { SystemTab } from 'src/modules/app/types/system-tab.interface';
import { FrontConfigsService } from "../../../../services/front-configs/front-configs.service";

export const ROLES_STORAGE_KEY = 'roles';
export const FAVORITE_ASSET_STORAGE_KEY = 'favoriteAsset';

const PUMPS_TYPE_SUFFIX = 'PUMPS';

@Component({
  selector: 'co2-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: [ './header-nav.component.scss' ]
})
export class HeaderNavComponent implements OnInit {
  userName$: Observable<string> = this.sb.userName$;
  myMatchOptions: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'exact',
    paths: 'exact',
    fragment: 'exact',
  };
  favoriteAsset = JSON.parse(localStorage.getItem(FAVORITE_ASSET_STORAGE_KEY) ?? '{}');
  assets!: string[];
  showDropdown: boolean = false;
  headerTabs: any = [];
  tabs$: Observable<Tab> = this.sb.tabsElements$;
  activeFpso$: Observable<string> = this.sb.activeFpso$;
  activeAffiliate$: Observable<string> = this.sb.activeAffiliate$;
  systems: SystemTab[] = [];
  pumpsSystems: any;

  // Define the custom order of system types

  constructor(
    private authService: AuthService,
    private sb: AppSandbox,
    public router: Router,
    private store: Store,
    private frontConfigsService: FrontConfigsService
  ) {

  }

  async ngOnInit() {
    combineLatest([ this.activeFpso$, this.activeAffiliate$ ]).subscribe(([ fpso, affiliate ]) => {
      if (!this.favoriteAsset) {
        this.favoriteAsset = { affiliate: affiliate, fpsos: fpso };
        localStorage.setItem(FAVORITE_ASSET_STORAGE_KEY, JSON.stringify(this.favoriteAsset));
      }
      this.frontConfigsService.getFpsosByAffiliate(affiliate).subscribe((sites) => {
        this.assets = sites;
        if (this.assets) {
          this.sb.loadUserName();
          this.sb.loadTabsConfiguration();
          this.buildTabConfiguration();
        } else {
          this.authService.redirectAuthenticatedUserToHome();
        }
      });
    });
  }

  private buildTabConfiguration() {
    this.tabs$.subscribe((data: any) => {
      if (data?.length) {
        let filteredSystems = data[0].systems.filter((system: any) => !system.systemType.includes('_PUMPS'));
        this.pumpsSystems = data[0].systems.filter((system: any) => system.systemType.includes('_PUMPS'));
        this.headerTabs = [ { systemType: 'DASHBOARD' }, ...filteredSystems, { systemType: 'PUMPS' } ];
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  onSelectFpso(fpso: string): void {
    const selectedSite = this.assets.find((asset: any) => asset.includes(fpso));

    if (selectedSite) {
      localStorage.setItem(FAVORITE_ASSET_STORAGE_KEY, JSON.stringify({
        affiliate: this.favoriteAsset.affiliate,
        fpsos: fpso
      }));
      this.sb.setActiveFpso(fpso);
    } else {
      this.authService.redirectAuthenticatedUserToHome();
    }
  }

  isActive(fpso: string): boolean {
    return fpso === this.store.selectSnapshot(ActiveFpsoState);
  }

  getSystemTypeValue(system: SystemTab | null): string {
    if (system === null) return '';
    if (system.systemType === 'DASHBOARD') return 'GENERAL DASHBOARD';
    if (system.systemType === 'PUMPS') return 'PUMPS';
    return system?.systemName;
  }

  getRouterLink(systemType: string): string {
    const [ affiliate, fpso ]: [ string, string ] = this.getAffiliateAndFpso();
    if (systemType.indexOf(PUMPS_TYPE_SUFFIX) > 0) {
      return `/${ affiliate }/${ fpso }/pumps/${ systemType }/overview`;
    }
    switch (systemType) {
      case 'DASHBOARD':
        return `/${ affiliate }/${ fpso }/general-dashboard`;
      case 'COMPRESSOR':
        return `/${ affiliate }/${ fpso }/compressors`;
      case 'TURBOGEN':
        return `/${ affiliate }/${ fpso }/power-generation/overview`;
      default:
        return ''; // Handle other cases or return a default value
    }
  }

  getQueryParams(systemType: string): any {
    if (systemType === 'COMPRESSOR') {
      return { 'compressor-type': 'overview' };
    }
    return null;
  }

  getSettingPage(path: string): string {
    const [ affiliate, fpso ]: [ string, string ] = this.getAffiliateAndFpso();
    return `/${ affiliate }/${ fpso }/settings/${ path }`;
  }

  getadminPage() {
    const [ affiliate, fpso ]: [ string, string ] = this.getAffiliateAndFpso();
    return `/${ affiliate }/${ fpso }/config`;
  }

  getAffiliateAndFpso(): [ string, string ] {
    return [ this.store.selectSnapshot(ActiveAffiliateState), this.store.selectSnapshot(ActiveFpsoState) ];
  }
}
