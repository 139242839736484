import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { EChartsOption } from "echarts";
import { buildSankeyLabelFormatter, SANKEY_GRAPH } from "./sankey.component.const";
import { cloneObject } from "../../utils/object-manipulation/object-manipulation";
import { SankeyInput } from "./sankey-input";
import { ELECTRICAL_POWER_OPTIMUM_KW_UNIT, FUEL_GAS_OPTIMUM_KTCO2_YEAR_UNIT, } from "../../types";
import { UiGraphComponent } from '../../components';
import { SankeyData } from "../../types/sankey.interface";
import {
  defaultSankeyColor,
  sankeyColorMap, sankeyDepth,
  SankeyType,
  SystemType
} from "../general-dashboard/general-dashboard.container.const";

const HEIGHT_BIG_SIZE = 360;
const HEIGHT_SMALL_SIZE = 190;
const WINDOW_LIMIT_WIDTH = 1500;

@Component({
  selector: 'co2-sankey',
  templateUrl: './sankey.component.html',
  styleUrls: [ './sankey.component.scss' ]
})
export class SankeyComponent {
  public options!: EChartsOption;
  public kwSelected = false;
  _sankey!: SankeyInput;
  @ViewChild(UiGraphComponent) UiGraphComponent!: UiGraphComponent;

  @Input()
  set sankey(value: SankeyInput) {
    if (value) {
      this._sankey = value;
      this.drawSankey();
    }
  }

  constructor(private el: ElementRef) {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.drawSankey();
  }

  get sankey() {
    return this._sankey;
  }

  handleSankeyExport() {
    if (this.UiGraphComponent) {
      this.UiGraphComponent.downloadImage(230);
    }
  }

  onChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.kwSelected = target?.checked;
    this.drawSankey();
  }

  private drawSankey() {
    const sankeyElement = this.el.nativeElement.querySelector('co2-ui-graph');
    if (window.innerWidth <= WINDOW_LIMIT_WIDTH) {
      const width = sankeyElement?.offsetWidth ? sankeyElement?.offsetWidth - 200 : undefined;
      this.updateSankey(width, HEIGHT_BIG_SIZE);
    } else {
      const width = sankeyElement?.offsetWidth ? sankeyElement?.offsetWidth - 220 : undefined;
      this.updateSankey(width, HEIGHT_SMALL_SIZE);
    }
  }

  updateSankey(width: number | undefined, height: number) {
    const graph = cloneObject(SANKEY_GRAPH);
    graph.height = height;
    graph.width = width;
    graph.series.links = this.kwSelected ? this.sankey.sankeyElectric : this.sankey.sankeyFuelGas;
    graph.series.data = this.enrichWithColors(this.sankey.data);
    graph.series.data = this.sankey.data;
    graph.series.label = {
      formatter: buildSankeyLabelFormatter()
    }
    this.options = graph;
  }



  private enrichWithColors(data: SankeyData[]) {
    data.forEach(item => {
      const type = Object.values(SankeyType).find(type => item.name.toLowerCase().includes(type.toLowerCase()));
      item.itemStyle = type ? (sankeyColorMap.get(type) || defaultSankeyColor) : defaultSankeyColor;
      item.depth = sankeyDepth.get(item.name) ?? undefined;
    })
    return data;
  }
}
