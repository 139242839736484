import { Injectable } from '@angular/core';
import {
  DEFAULT_SYSTEM_GRAPH_NAME,
  getGraphMetaDataFrom,
  LAST_3_DAYS_GRAPH_TITLE,
  LAST_3_YEARS_GRAPH_TITLE,
  SHARED_LINE_SERIE_CONFIG,
  ValueSeries
} from "../../types";
import { EChartsOption, SeriesOption, TooltipComponentOption } from "echarts";
import { TitleOption, YAXisOption } from "echarts/types/dist/shared";
import { DEFAULT_LINE_CHART_GRAPHS_MAP, DEFAULT_YAXIS_PERFORMANCE_DRIFT_INPUTS } from "./common-graphs.const";
import { performanceDriftFormatter, tooltipFormatter } from "../../utils";
import { cloneObject } from "../../utils/object-manipulation/object-manipulation";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class CommonGraphsService {

  private readonly _performanceDriftGraphsTitles =
    {
      firstTitle: LAST_3_YEARS_GRAPH_TITLE,
      secondTitle: LAST_3_DAYS_GRAPH_TITLE
    };

  public updateManyEquipmentsPerformanceDriftGraphs(valueSeriesList: ValueSeries[],
    title: string,
    performanceDriftGraphList: EChartsOption[],
    graphName: string = DEFAULT_SYSTEM_GRAPH_NAME,
    graphMetadata: { [x: string]: any; }[],
    grid?: number): EChartsOption[] {
    let graph: EChartsOption = this.getGraph(title, grid);
    if (valueSeriesList.length === 0) {
      // If valueSeriesList is empty, set up an empty graph
      graph.series = [];
      performanceDriftGraphList.push(graph);
    } else {
      graph.series = this.setPerformanceDriftSeriesOptions(valueSeriesList, graph, graphName, graphMetadata);
      performanceDriftGraphList.push(graph);
      if (performanceDriftGraphList.length == Object.keys(this._performanceDriftGraphsTitles).length) {
        performanceDriftGraphList =
          this.reorderTwoGraphsByTitleText(performanceDriftGraphList,
            this._performanceDriftGraphsTitles.firstTitle,
            this._performanceDriftGraphsTitles.secondTitle);
      }
    }
    return performanceDriftGraphList;
  }


  private getGraph(title: string, grid?: number): EChartsOption {
    let graph: any = DEFAULT_LINE_CHART_GRAPHS_MAP.get(title) || {};
    graph.title = { text: title };

    graph.xAxis = {
      ...graph.xAxis,
      max: this.getFormattedDate('current'),
      min: this.getFormattedDate(title === LAST_3_DAYS_GRAPH_TITLE ? '3days' : '3years')
    };

    graph.tooltip = graph.tooltip || {}; // Initialiser graph.tooltip avec un objet vide s'il est undefined
    if (grid) {
      graph.grid = {
        bottom: grid,
      }
    }
    (graph.tooltip as TooltipComponentOption).formatter = tooltipFormatter();
    return graph;
  }

  getFormattedDate(period: 'current' | '3days' | '3years'): string | undefined {
    switch (period) {
      case '3days':
        return moment().subtract(3, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      case 'current':
        return moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      default:
        return undefined;
    }
  }


  private setPerformanceDriftSeriesOptions(valueSeriesList: ValueSeries[], graph: EChartsOption, graphName: string, graphMetadata: { [x: string]: any; }[]): SeriesOption[] {
    let performanceDriftSeriesOptions: SeriesOption[] = [];

    const yAxis = this.filterYAxisNamesAndUnits(valueSeriesList, graphName, graphMetadata);

    const yAxisNames = yAxis.map((y, index) => y.yAxisName);
    const yAxisUnits = yAxis.map((y, index) => y.yAxisUnit);

    graph.yAxis = this.buildYAxisForGraph(yAxisNames, yAxisUnits);
    graph.xAxis = { ...graph.xAxis };

    graph.tooltip = this.buildPerformanceDriftTooltip(graph, graphMetadata);

    valueSeriesList.forEach(valueSeries => {
      const valueSeriesName = valueSeries.name.length > 0 ? valueSeries.name : graphName;
      const equipmentConfig = getGraphMetaDataFrom(graphMetadata, valueSeriesName);
      if (!equipmentConfig) return;
      const yAxisName = equipmentConfig.yAxisName;
      const yAxisIndex = yAxisNames.indexOf(yAxisName);

      performanceDriftSeriesOptions.push({
        name: valueSeriesName,
        ...SHARED_LINE_SERIE_CONFIG,
        ...equipmentConfig,
        yAxisIndex: yAxisIndex,
        data: valueSeries.data,
        symbol: 'circle',
      });
    });
    return performanceDriftSeriesOptions;
  }

  private buildPerformanceDriftTooltip(graph: EChartsOption, graphMetadata: { [x: string]: any; }[]) {
    return {
      ...graph.tooltip,
      formatter: performanceDriftFormatter(graphMetadata)
    } as TooltipComponentOption;
  }

  private buildYAxisForGraph(yAxisNames: string[], yAxisUnits: string[]) {
    return yAxisNames.map((yName, index) => {
      let name = index === 0 ? `Deviation from ${yName}` : `${yAxisUnits[index]}`;
      const yAxis = { ...cloneObject(DEFAULT_YAXIS_PERFORMANCE_DRIFT_INPUTS), name: name } as YAXisOption;
      if (index > 0 && yAxis?.nameTextStyle?.padding) {
        yAxis.nameTextStyle.padding = [0, 0, 0, 0];
      }
      return yAxis;
    });
  }

  private filterYAxisNamesAndUnits(valueSeriesList: ValueSeries[], graphName: string, graphMetadata: { [x: string]: any; }[]) {
    return valueSeriesList
      .map(valueSeries => valueSeries.name.length > 0 ? valueSeries.name : graphName)
      .map(name => {
        return getGraphMetaDataFrom(graphMetadata, name) as { yAxisName: string, yAxisUnit: string }
      })
      .filter(a => a !== undefined)
      // remove duplicates
      .filter((v, i, a) => a.findIndex(t => (t.yAxisName === v.yAxisName && t.yAxisUnit === v.yAxisUnit)) === i);
  }

  public reorderTwoGraphsByTitleText(graphList: EChartsOption[], firstTitle: string, secondTitle: string)
    : EChartsOption[] {
    let firstGraph: EChartsOption = this.getEChartsOptionByTitleText(graphList, firstTitle);
    let secondGraph: EChartsOption = this.getEChartsOptionByTitleText(graphList, secondTitle);
    return [firstGraph, secondGraph];
  }

  private getEChartsOptionByTitleText(graphList: EChartsOption[], titleText: string) {
    const index = graphList.findIndex(graph =>
      graph?.title !== undefined && (graph?.title as TitleOption).text == titleText);
    return (index < 0) ? {} : graphList[index];
  }
}
