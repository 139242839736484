<ul  class="nav nav-pills">
  <li *ngIf="showHomeButton" class="nav-item">    <button  class="nav-link" type="button" (click)="goToHomePage()"
   ><span class="material-icons home" aria-hidden="true">home</span></button></li>
  <li *ngFor="let tab of tabsList" class="nav-item" [class.disabled]="tab.disabled">
    <button *ngIf="tab.label" class="nav-link" type="button" [class.active]="tab.id === activeTab"
            (click)="selectTab.emit(tab.id)">{{tab.label}}</button>
    <button *ngIf="tab.fpso" class="nav-link" type="button" [class.active]="tab.id === activeTab"
            (click)="selectTab.emit(tab.id)">{{tab.fpso}}</button>
  </li>
</ul>
