import {
  LinearGraph,
  DEFAULT_YAXIS_INPUTS,
  GraphInputs,
  DEFAULT_LINEAR_GRAPH,
  DEFAULT_XAXIS,
  DEFAULT_LEGEND, TableHeader, DataType
} from '../../types';

export const MAX_POLYTROPIC_GOOD_ROW_VALUE: number = 5;
export const MAX_POLYTROPIC_AVERAGE_ROW_VALUE: number = 10;
export const MAX_CO2_FACTOR_ROW_VALUE: number = 700;
export const MIN_CO2_FACTOR_ROW_VALUE: number = 500;

export const ENERGY_PERF_GRAPH: GraphInputs = {
  graph: LinearGraph.ENERGY_PERFORMANCE,
  ...DEFAULT_LINEAR_GRAPH,
  yAxis: { ...DEFAULT_YAXIS_INPUTS, name: 'Sm3/kW.h' },
  xAxis: { ...DEFAULT_XAXIS, axisLabel: { formatter: '{dd}/{MM}/{yyyy}' } },
  legend: { ...DEFAULT_LEGEND },
  grid: {
    left: 70,
    bottom: '23%',
  },
};

export const POWER_GRAPH: GraphInputs = {
  graph: LinearGraph.POWER,
  ...DEFAULT_LINEAR_GRAPH,
  yAxis: { ...DEFAULT_YAXIS_INPUTS, name: 'Consumed Power' },
  xAxis: { ...DEFAULT_XAXIS, axisLabel: { formatter: '{dd}/{MM}/{yyyy}' } },
  legend: { ...DEFAULT_LEGEND },
  grid: {
    left: 70,
    bottom: '23%',
  },
};

export const ASV_GRAPH: GraphInputs = {
  graph: LinearGraph.ASV,
  ...DEFAULT_LINEAR_GRAPH,
  yAxis: [
    { ...DEFAULT_YAXIS_INPUTS, name: 'ASV %' },
    {
      ...DEFAULT_YAXIS_INPUTS,
      name: 'ktCO2eq/year',
      position: 'right',
      axisLine: {
        show: true,
        lineStyle: {
          color: '#CCCCCC',
          type: 'dashed',
          width: 3,
        }
      },
    },
  ],
  xAxis: { ...DEFAULT_XAXIS, axisLabel: { formatter: '{dd}/{MM}/{yyyy}' } },
  legend: { ...DEFAULT_LEGEND },
  grid: {
    left: 70,
    bottom: '23%',
  },
};

export const POLYTROPIC_GRAPH: GraphInputs = {
  graph: LinearGraph.POLYTROPIC,
  ...DEFAULT_LINEAR_GRAPH,
  yAxis: [
    { ...DEFAULT_YAXIS_INPUTS, name: 'Polytropic effeciency %' },
    {
      ...DEFAULT_YAXIS_INPUTS,
      name: 'ktCO2eq/year',
      position: 'right',
      axisLine: {
        show: true,
        lineStyle: {
          color: '#CCCCCC',
          type: 'dashed',
          width: 3,
        }
      },
    },
  ],
  xAxis: { ...DEFAULT_XAXIS, axisLabel: { formatter: '{dd}/{MM}/{yyyy}' } },
  legend: { ...DEFAULT_LEGEND },
  grid: {
    left: 70,
    bottom: '23%',
  },
};

export const GRAPHS_INPUT: GraphInputs[] = [
  {
    graph: LinearGraph.ENERGY_PERFORMANCE,
    ...DEFAULT_LINEAR_GRAPH,
    yAxis: { ...DEFAULT_YAXIS_INPUTS, name: 'Sm3/kW.h' },
    xAxis: { ...DEFAULT_XAXIS },
    legend: { ...DEFAULT_LEGEND },
    grid: {
      left: 70,
      bottom: '23%',
    },
  },
  {
    graph: LinearGraph.POWER,
    ...DEFAULT_LINEAR_GRAPH,
    yAxis: { ...DEFAULT_YAXIS_INPUTS, name: 'kW' },
    xAxis: { ...DEFAULT_XAXIS },
    legend: { ...DEFAULT_LEGEND },
    grid: {
      left: 70,
      bottom: '23%',
    },
  },
  {
    graph: LinearGraph.ASV,
    ...DEFAULT_LINEAR_GRAPH,
    yAxis: [
      { ...DEFAULT_YAXIS_INPUTS, name: '%' },
      {
        ...DEFAULT_YAXIS_INPUTS,
        name: 'ktCO2eq/year',
        position: 'right',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#CCCCCC',
            type: 'dashed',
            width: 3,
          }
        },
      },
    ],
    xAxis: { ...DEFAULT_XAXIS },
    legend: { ...DEFAULT_LEGEND },
    grid: {
      left: 70,
      bottom: '23%',
    },
  },
  {
    graph: LinearGraph.POLYTROPIC,
    ...DEFAULT_LINEAR_GRAPH,
    yAxis: [
      { ...DEFAULT_YAXIS_INPUTS, name: '%' },
      {
        ...DEFAULT_YAXIS_INPUTS,
        name: 'ktCO2eq/year',
        position: 'right',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#CCCCCC',
            type: 'dashed',
            width: 3,
          }
        },
      },
    ],
    xAxis: { ...DEFAULT_XAXIS },
    legend: { ...DEFAULT_LEGEND },
    grid: {
      left: 70,
      bottom: '23%',
    },
  },
];

export const GLOBAL_COMPRESSOR_HEADERS: TableHeader[] = [
  {
    id: 'runningEquipmentsCount',
    label: 'Running Compressors',
    dataType: DataType.TEXT,
  },
  {
    id: 'consumedPower',
    label: 'Energy Consumption',
    dataType: DataType.TEXT,
    suffixText: 'kW',
  },
  {
    id: 'emissionsOverconsumption',
    label: 'Emissions related to overconsumption',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/yr',
  },
  {
    id: 'deviationTargetedEnergy',
    label: 'Deviation from targeted Energy consumption',
    dataType: DataType.TEXT,
    suffixText: 'kW'
  },
  {
    id: 'emissionsASVOpening',
    label: 'Emissions related to ASV opening',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/yr',
  },
  {
    id: 'emissionsPolytropicEfficiencyDegradation',
    label: 'Emissions related to Polytropic efficiency degradation',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/yr',
  },
  {
    id: 'emissionsPerformanceDrift',
    label: 'Emissions related to performance drift',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/yr',
  }
];

export const DETAILS_COMPRESSOR_HEADERS: TableHeader[] = [
  {
    id: 'equipmentStatus',
    label: 'Status',
    dataType: DataType.TEXT,
  },
  {
    id: 'equipmentName',
    label: 'Compressor',
    dataType: DataType.TEXT
  },
  {
    id: 'consumedPower',
    label: 'Energy Consumption',
    dataType: DataType.TEXT,
    dynamicSuffix: true
  },
  {
    id: 'emissionsOverconsumption',
    label: 'Emissions related to overconsumption',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/yr',
  },
  {
    id: 'deviationTargetedEnergy',
    label: 'Deviation from targeted Energy consumption',
    dataType: DataType.TEXT,
    dynamicSuffix: true
  },
  {
    id: 'emissionsPerformanceDrift',
    label: 'Emissions related to performance drift',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/yr',
  },
  {
    id: 'asvOpening',
    subId: 'stages',
    subIdTitle: 'stageName',
    label: 'Anti surge valve position',
    dataType: DataType.LIST,
    suffixText: '%'
  },
  {
    id: 'emissionsASVOpening',
    label: 'Emissions related to ASV opening',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/yr',
  },
  {
    id: 'polytropicEfficiency',
    subId: 'stages',
    subIdTitle: 'stageName',
    subRefId: 'polytropicEfficiencyReference',
    label: 'Polytropic Efficiency',
    dataType: DataType.LIST,
    suffixText: '%'
  },
  {
    id: 'emissionsPolytropicEfficiencyDegradation',
    label: 'Emissions related to Polytropic efficiency degradation',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/yr',
  },
  {
    id: 'pressureLoss',
    subId: 'stages',
    subIdTitle: 'stageName',
    label: 'Intra system pressure Loss',
    dataType: DataType.LIST,
    suffixText: ' bar'
  },
  {
    id: 'intraSystemEmission',
    label: 'Emissions related to pressure loss',
    dataType: DataType.TEXT,
    suffixText: 'ktCO2/yr'
  }
];
