<div class="card" id="crossplot" *ngIf="crossPlotGraphInput && crossPlotGraphInput.series">
  <div class="card-title">
    <span class="material-icons-round card-header-icon">graphic_eq&nbsp;</span>
    {{crossPlotTitle}}
  </div>

  <div class="card-body">
    <div class="body" *ngIf="!isLoading && !hasLoadingError">
      <div *ngIf="filtersAreDisplayed" class="card-selection">
        <div [ngClass]="!editMode ? 'firstBloc' : 'biggerBloc'">
          <co2-date-selection *ngIf="displayDatePicker" [startDate]="startDatePicker" [endDate]="endDatePicker"
            [quickSelection]=true [enableLimitationMinDate]="false"
            (datePick)="datePick.emit($event)"></co2-date-selection>
          <div *ngIf="filtersAreDisplayed && isAdmin" class="card-baseline">
            <div class="card-titles">
              <div class="form-text">Baseline: </div>
              <div *ngIf="!editMode" class="link" (click)="toggleEditBaseline()" (keydown)="toggleEditBaseline()"><span
                  class="material-icons-outlined">edit</span> Edit </div>
              <div *ngIf="editMode" class="link actions">
                <div class="link cancel" (click)="resetGraph()" (keydown)="resetGraph()">Cancel </div>
                <button type="button" class="btn btn-secondary" (click)="saveEdit()" (keydown)="saveEdit()">
                  <span class="material-icons" aria-hidden="true">done</span> Save edit
                </button>
              </div>
            </div>
            <div *ngIf="editMode || mustShowNewBaseline(); else showDescription" class="card-content">
              <div *ngIf="newBaselineUpdatedBy && mustShowNewBaseline(); else showHowWhen"
                class="form-text modified-by">Today last modification by <span
                  class="subtitle">{{newBaselineUpdatedBy}}</span> at <span class="subtitle">{{ newBaselineUpdatedAt |
                  date: "HH'h'mm" }}</span></div>
              <ng-template #showHowWhen>
                <div *ngIf="newBaselineUpdatedBy && newBaselineUpdatedAt" class="form-text modified-by">Last
                  modification by <span class="subtitle">{{newBaselineUpdatedBy}}</span> at <span class="subtitle">{{
                    newBaselineUpdatedAt | date: "dd/MM/yyyy HH'h'mm" }}</span></div>
              </ng-template>
              <div class="label">
                <div class="legend"></div>
                <div class="form-text">Min - Max <span class="subtitle">(select points on graph)</span></div><br />
              </div>
              <div *ngIf="firstPoint?.length && lastPoint?.length" class="coordinates">
                <div class="point">
                  <div class="point-line"> <span class="point-title">Xmin</span><span
                      class="form-text">{{firstPoint[0].toFixed(0)}}</span></div>
                  <div class="point-line"><span class="point-title">Ymin</span><span
                      class="form-text">{{firstPoint[1].toFixed(0)}}</span></div>
                </div>
                <div class="point">
                  <div class="point-line"> <span class="point-title">Xmax</span><span
                      class="form-text">{{lastPoint[0].toFixed(0)}}</span></div>
                  <div class="point-line"><span class="point-title">Ymax</span><span
                      class="form-text">{{lastPoint[1].toFixed(0)}}</span></div>
                </div>
              </div>
            </div>
            <ng-template #showDescription>
              <div class="label">
                <div class="form-text">Modifications can only be made by an administrator.</div>
              </div>
            </ng-template>
          </div>
        </div>

      </div>
      <div style="
      width: 67%;">
        <co2-ui-graph [options]="crossPlotGraphInput" [isModificationBaselineIsOn]="isAdmin" [editMode]="editMode"
          (chartClick)="chartClick.emit($event)" (coordinatesChange)="handleCoordinatesChange($event)"></co2-ui-graph>
        <div class="details">
          <div *ngIf="showLegendInfos" class="details__points">* : Points are calculated for the last 3 days where the
            data is available.</div>
          <a [routerLink]="['/', affiliate, fpso, 'settings', 'baselines']" rel="noopener noreferrer" target="_blank"
            class="link-primary arrow formula-link">More Details</a>
        </div>
      </div>
    </div>

    <ngx-skeleton-loader *ngIf="isLoading" count="12" appearance="line"></ngx-skeleton-loader>

    <co2-card-error *ngIf="hasLoadingError && !isLoading" (tryAgain)="reloadData.emit()"></co2-card-error>
  </div>
</div>
