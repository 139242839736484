<table *ngIf="rows" class="table" cdk-table [dataSource]="rows" [class.disabled]="disabled" aria-describedby="table">
  <ng-container *ngFor="let header of headers" [cdkColumnDef]="header.id">
    <th scope="col" cdk-header-cell *cdkHeaderCellDef>
      <span
        [ngClass]="header.label === 'Avoidable emissions' ? 'd-block-fix' : 'd-block' && header.label === 'Status' ? 'd-block-none' : '' ">{{
        header.label }}</span>
      <span class="material-icons-outlined" *ngIf="header.tooltip"
        [ngClass]="header.label === 'Status' ? 'd-block-none' : ''" [tooltip]="header.tooltip"
        [position]="TooltipPosition.ABOVE" [theme]="TooltipTheme.LIGHT">info</span>
      <span *ngIf="header.subHeader" [ngClass]="header.label === 'Status' ? 'd-block-none' : ''" class="sub-header">{{
        header.subHeader }}</span>
    </th>
    <td cdk-cell *cdkCellDef="let row">
      <div *ngIf="header.dataType === DATA_TYPE.TEXT" class="table-data"
        [ngClass]="getDataClass(header.id, row[header.id])">
        <span class="row-title"> {{ row[header.id] | displayNA }} </span>


        <span *ngIf="header.suffixText && row[header.id] !== undefined && row[header.id] !== null" class="suffix-text">
          {{ header.suffixText }}
        </span>
      </div>

      <div *ngIf="header.dataType === (DATA_TYPE.HTML_TEXT || DATA_TYPE.STATUS)" class="table-data"
        [ngClass]="getDataClass(header.id, row[header.id])">
        <span [innerHTML]="row[header.id]" class="row__title"> | displayNA</span>
        <span *ngIf="header.suffixText && row[header.id] !== undefined && row[header.id] !== null" class="suffix-text">
          {{ header.suffixText }}
        </span>
        <div *ngIf="header.metadata[row['alertStatus']]" class="status title">
          <span class="{{header.metadata[row['alertStatus']].statusClass}}">
            <span class="material-icons {{header.metadata[row['alertStatus']].iconClass}}">
              {{ header.metadata[row['alertStatus']].icon }}
            </span>
            {{ header.metadata[row['alertStatus']].label }} </span>
        </div>
      </div>

      <div>
        <div *ngIf="header.dataType === DATA_TYPE.BADGE" class="table-data badge badge-m"
          [style.--badgeColor]="getBadgeColors(row).color" style="white-space: pre-line;"
          [style.--badgeBackgroundColor]="getBadgeColors(row).badgeBackgroundColor">
          <span> {{ row[header.id] | displayNA: header.defaultValue }} </span>

          <span *ngIf="header.suffixText && row[header.id] !== undefined && row[header.id] !== null"
            class="suffix-text">
            {{ header.suffixText }}
          </span>
        </div>


        <div *ngIf="header.subHeaderId && row[header.subHeaderId] !== undefined && row[header.subHeaderId] !== null"
          class="table-data sub-header-id">
          <span *ngIf="header.subHeaderId && row[header.subHeaderId] !== undefined && row[header.subHeaderId] !== null">
            {{ row[header.subHeaderId] }}
          </span>
        </div>
      </div>
      <div *ngIf="header.dataType === DATA_TYPE.GRAPH" class="table-data cdk-header-row"
      [ngClass]="displayedHeaders.length === 4 ? 'x-large-height' : getDataClass(header.id, row[header.id])">
        <co2-ui-graph *ngIf="!row[header.id]?.legend" [options]="row[header.id]" class="inside-table"></co2-ui-graph>
        <co2-ui-graph *ngIf="row[header.id]?.legend" [options]="row[header.id]" class="inside-warning"></co2-ui-graph>
      </div>
    </td>
  </ng-container>

  <tr cdk-header-row *cdkHeaderRowDef="displayedHeaders"></tr>
  <tr cdk-row *cdkRowDef="let row; columns: displayedHeaders" [class.clickable]="clickableRows"
    (click)="onRowClick(row)" [class.selected]="rowIsSelected(row)" (keypress)="onRowClick(row)"
    [ngClass]="{'warning': displayedHeaders.length === 4, 'no-issue': row.avoidableEmissions === null || row.avoidableEmissions === 0}">
  </tr>

</table>
