import { Component, Input, OnInit } from '@angular/core';
import { take } from "rxjs";
import { FileUploadService } from "../../services/file-upload/file-upload.service";
import {
  ADMIN_CONFIG_API, FRONT_CONFIG_API
} from "../../services/file-upload/file-upload.service.const";
import { ResponseMessage } from "../../types/response-message.interface";
import { FAVORITE_ASSET_STORAGE_KEY } from "../../containers";
import { FrontConfigsService } from "../../services/front-configs/front-configs.service";

@Component({
  selector: 'co2-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() componentId: string = 'file';
  @Input() title: string = 'config';
  file: any;
  fileName!: string;
  message!: string;
  uploadSuccessful = false;
  favoriteAsset = JSON.parse(localStorage.getItem(FAVORITE_ASSET_STORAGE_KEY) ?? '{}');
  assets!: string[];

  constructor(
    private fileUploadService: FileUploadService,
    private frontConfigsService: FrontConfigsService
  ) {}

  ngOnInit(): void {
    this.frontConfigsService.getFpsosByAffiliate(this.favoriteAsset.affiliate).subscribe((sites) => {
      this.assets = sites;
    });
  }

  selectFile($event: any): void {
    const file = $event.target.files[0];
    this.file = file.name.endsWith('.json') ? file : undefined;
    this.fileName = this.file?.name;
    this.message = '';
    this.uploadSuccessful = false;
  }

  upload(): void {
    if (this.file) {
      const apiUrl: string = (this.title.toLowerCase().includes('admin') ? ADMIN_CONFIG_API : FRONT_CONFIG_API) + "/upload";
      this.fileUploadService
        .uploadFile(this.favoriteAsset.affiliate, this.file, apiUrl)
        .pipe(take(1))
        .subscribe({
          next: this.handleSuccessResponse,
          error: this.handleErrorResponse,
          complete: this.resetFile
        });
    }
  }

  handleSuccessResponse = (response: ResponseMessage): void => {
    if (response.message) {
      this.message = response.message;
      this.uploadSuccessful = true;
      this.resetFile();
    }
  }

  handleErrorResponse = (response: any): void => {
    this.message = 'Upload failed!';
    this.uploadSuccessful = false;
    this.resetFile();
  }

  resetFile(): void {
    const fileInput = document.getElementById(`upload-input-${this.componentId}`) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    this.file = '';
    this.fileName = '';
  }

  download(fpso: string) {
    const apiUrl: string = (this.title.toLowerCase().includes('admin') ? ADMIN_CONFIG_API : FRONT_CONFIG_API) + "/config/download";
    const suffix: string = this.title.toLowerCase().includes('admin') ? "ADMIN" : "FRONT" ;
    this.fileUploadService.downloadFile(this.favoriteAsset.affiliate, fpso, apiUrl).subscribe({
      next: (response) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.favoriteAsset.affiliate}_${fpso}_${suffix}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      error: (error) => {
        console.error('File download error:', error);
      }
    });
  }
}
