<div class="card">
  <div class="card-title">
    <span class="material-icons-round card-header-icon">graphic_eq&nbsp;</span>
    Performance Drift
  </div>

  <div class="card-body">
    <div class="co2-graph-body" *ngIf="!isLoading && !hasLoadingError && (dailyPerformanceDriftGraphInputs || hourlyPerformanceDriftGraphInputs)">
      <co2-date-selection
      [startDate]="startDatePicker"
      [endDate]="endDatePicker"
      [quickSelection]=true
      [enableLimitationMinDate]="true"
      (datePick)="datePick.emit($event)">
      </co2-date-selection>

      <div class="co2-graph">
        <co2-ui-graph *ngIf="dailyPerformanceDriftGraphInputs" [options]="dailyPerformanceDriftGraphInputs" [noAvailableData]="isNoDataAvailable(dailyPerformanceDriftGraphInputs)"></co2-ui-graph>
        <co2-ui-graph *ngIf="hourlyPerformanceDriftGraphInputs" [options]="hourlyPerformanceDriftGraphInputs" [noAvailableData]="isNoDataAvailable(hourlyPerformanceDriftGraphInputs)"></co2-ui-graph>
      </div>
    </div>

    <div class="co2-graph-no-sensor" [hidden]="!isLoading && !hasLoadingError && (dailyPerformanceDriftGraphInputs || hourlyPerformanceDriftGraphInputs)">
      {{ getNonAvailabilityMessage() }}
    </div>

    <ngx-skeleton-loader *ngIf="isLoading" count="6" appearance="line"></ngx-skeleton-loader>

    <co2-card-error *ngIf="hasLoadingError && !isLoading" (tryAgain)="reloadData.emit()"></co2-card-error>
  </div>
</div>
