import { AlertRules, AlertStatus } from './alert-rules.enum';
import { LinearChart } from './linear-chart.interface';
import { PointCouple } from './value.series';

export interface Alert {
  id: string,
  alertStatus: AlertStatus;
  alertType: AlertRules;
  systemType: string;
  value: number | null | string;
  avoidableEmissions: number;
  ytdAvoidableEmissions?: string | number | null;
  date: string;
  lastDate: string;
  comment: string;
  observation: string;
  equipmentName: string;
  equipmentType: string;
  trendGraph?: LinearChart;
  hourlyTrendGraph?: LinearChart;
  nameWithType?: string;
  hourlyTrendData: PointCouple[];
  trendData: PointCouple[];
  label?:string;
}

export interface Warning {
  id: string,
  alertStatus: AlertStatus;
  alertType: AlertRules;
  systemType: string;
  value?: number | null | string;
  avoidableEmissions?: number;
  ytdAvoidableEmissions?: string | number | null;
  date?: string;
  lastDate?: string;
  comment: string;
  observation?: string;
  equipmentName: string;
  equipmentType: string;
  trendGraph?: LinearChart;
  hourlyTrendGraph?: LinearChart;
  nameWithType?: string;
  hourlyData: PointCouple[];
  dailyData: any;
  originalDailyData:any;
  originalHourlyData:any;
}

export interface Observations {
  alerts: Alert[];
  totalSize: number;
}

export interface ObservationsWarnings {
  warnings: Warning[];
  totalSize: number;
}

export function toAlert(dto: Alert): Alert {
  return {
    id: dto.id,
    alertType: dto.alertType,
    alertStatus: dto.alertStatus,
    systemType: dto.systemType,
    value: dto.value,
    ytdAvoidableEmissions: dto.ytdAvoidableEmissions ? `${dto.ytdAvoidableEmissions} ktCO2 YTD`: null,
    avoidableEmissions: dto.avoidableEmissions,
    date: dto.date,
    lastDate: dto.lastDate,
    comment: dto.comment,
    observation: dto.observation,
    equipmentName: dto.equipmentName,
    equipmentType: dto.equipmentType,
    trendData: dto.trendData,
    trendGraph: dto.trendGraph,
    hourlyTrendGraph: dto.hourlyTrendGraph,
    nameWithType: dto.nameWithType,
    hourlyTrendData: dto.hourlyTrendData,
  }
}

export function toObservations(observations: Observations): Observations {
  return {
    alerts: observations.alerts.map(toAlert),
    totalSize: observations.totalSize,
  }
}
