import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, switchMap } from 'rxjs';
import { environment } from '@environments/environment';
import { getUserTimezone } from '../../utils';
import { toPointCouple } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class WarningService {

  constructor(private http: HttpClient) { }

  getDailyDataWarnings(affiliate: string, fpsoName: string, equipmentName: string, systemType: string): Observable<any>{


  const params = new HttpParams().set('system_type', systemType)
    .append('equipment_name', equipmentName)
    .append('timezone', getUserTimezone());

    return this.http.get<any[]>(
      `${ environment.apiUrl }/${affiliate}/${fpsoName}/warning/daily-trend`,
      { params: params }
    );
 }

 getWarnings(affiliate: string, fpsoName: string): Observable<any> {
    const filter = "";
    const params= new HttpParams().set('filter', filter);

    return this.http.get<any[]>(
        `${ environment.apiUrl }/${affiliate}/${fpsoName}/warning/latest`,
        { params: params }
      );
 }

 getHourlyDataWarnings(affiliate: string, fpsoName: string, equipmentName: string, systemType: string): Observable<any> {
  const params = new HttpParams().set('system_type', systemType)
    .append('equipment_name', equipmentName)
    .append('timezone', getUserTimezone());

    return this.http.get<any[]>(
        `${ environment.apiUrl }/${affiliate}/${fpsoName}/warning/hourly-trend`,
        { params: params }
      );
 }

 getWarningsWithTrends(affiliate: string, fpsoName: string): Observable<any> {
  const params = new HttpParams().set('filter', "");

  // Step 1: Get warnings
  return this.getWarnings(affiliate, fpsoName).pipe(
    switchMap(warnings => {
      const warningRequests = warnings.map((warning:any) => {
        // Step 2: Fetch daily and hourly data for each warning
        return forkJoin([
          this.getHourlyDataWarnings(affiliate, fpsoName, warning.equipmentName, warning.systemType),
          this.getDailyDataWarnings(affiliate, fpsoName, warning.equipmentName, warning.systemType)
        ]).pipe(
          map(([hourlyData, dailyData]) => {
            // Step 3: Add hourly and daily data to the warning object
            warning.hourlyData = hourlyData.data.map(toPointCouple);
            warning.dailyData = dailyData.data.map(toPointCouple);
            warning.originalDailyData = dailyData.dataOriginal.map(toPointCouple);
            warning.originalHourlyData = hourlyData.dataOriginal.map(toPointCouple);
            warning.warningType= true;
            warning.observation = 'This warning may correspond to other issues than Equipment performance (example: PI Tag or sensor issues or inconsistent results)'
            return warning;
          })
        );
      });

      // Wait for all warning requests to complete
      return forkJoin(warningRequests);
    }),
    map(warningsWithTrends => {
      // You can further transform or map the data if needed
      return {
        warnings: warningsWithTrends
      };
    })
  );
}



}