<co2-tab-selector [tabsList]="subTabs" [activeTab]="activeCompressorsType$ | async"
                  (selectTab)="onSelectTab($event)">
</co2-tab-selector>

<div *ngIf="(activeCompressorsType$ | async) !== OVERVIEW">
  <ng-container *ngFor="let c of crossPlots; let i = index; trackBy: trackByFn">
    <co2-cross-plot [crossPlotGraphInput]="crossPlotGraphs[i]" [crossPlotTitle]="crossPlotTitles[i]"
      [isLoading]="compressorCrossPlotLoader$ | async" [hasLoadingError]="compressorCrossPlotError$ | async"
      (reloadData)="sb.loadCompressorCrossPlot(allEquipmentNames, equipmentNamesByType)" (chartClick)="onChartClick($event, i)" (showAllData)="showAllDataForCrossplot($event, i)"
      [displayDatePicker]="true" [startDatePicker]="datePickerProperties.startDatePicker" [showLegendInfos]="true"
      [endDatePicker]="datePickerProperties.endDatePicker" (datePick)="filterDatePick($event, i)" [equipmentName]="this.sb.getCompressorEquipmentNames(allEquipmentNames, equipmentNamesByType)[i]">
    </co2-cross-plot>
  </ng-container>
  <co2-performance-drift [performanceDriftGraphInputs]="performanceDriftCompGraph"
    [isLoading]="compressorsCombinedPerformanceDriftLoader$ | async"
    [hasLoadingError]="compressorsCombinedPerformanceDriftError$ | async" (reloadData)="sb.loadCompressorsPerformanceDrift(COMPRESSOR_TYPE, getStartDateSince3Years(), allEquipmentNames, equipmentNamesByType);
                         sb.loadCompressorsHourlyPerformanceDrift(COMPRESSOR_TYPE, allEquipmentNames, equipmentNamesByType)" [displayDatePicker]="true"
    [startDatePicker]="this.datePickerProperties.startDatePicker"
    [endDatePicker]="this.datePickerProperties.endDatePicker" (datePick)="filterDatePickDrift($event)">
  </co2-performance-drift>

  <co2-energy-performance
    [energyType]="energyType"
    [powerGraphInputs]="powerGraphInputs"
    [powerGraphIsLoading]="compressorsPowerDataLoader$ | async"
    [hasPowerGraphLoadingError]="compressorsPowerDataError$ | async"
    (reloadPowerGraphData)="sb.loadCompressorsEmissionData(COMPRESSOR_INDICATOR.POWER, getSelectedSubEquipmentType())" [asvGraphInputs]="asvGraphInputs"
    [asvGraphIsLoading]="compressorsAsvDataLoader$ | async" [hasAsvGraphLoadingError]="compressorsAsvDataError$ | async"
    (reloadAsvGraphData)="sb.loadCompressorsEmissionData(COMPRESSOR_INDICATOR.ASV, getSelectedSubEquipmentType())"
    [polytropicGraphInputs]="polytropicGraphInputs"
    [polytropicGraphIsLoading]="compressorsPolytropicDataLoader$ | async"
    [hasPolytropicGraphLoadingError]="compressorsPolytropicDataError$ | async"
    (reloadPolytropicGraphData)="sb.loadCompressorsEmissionData(COMPRESSOR_INDICATOR.POLYTROPIC_EFFICIENCY, getSelectedSubEquipmentType())"
    (toggleGraph)="onToggleGraph($event)">
  </co2-energy-performance>

</div>

<div *ngIf="(activeCompressorsType$| async) === OVERVIEW">
  <co2-overview-kpis *ngIf="globalKPIValues"
    [equipmentOverview] = "kpiTitle"
    [globalheaders] ="globalCompressorHeaders"
    [globalRows] ="globalKPIValues"
    [globalDisplayedHeaders] ="globalCompressorDisplayedHeaders"
    [datePumps]="lastDate"
    equipment="Compressor"
    [detailsHeaders]="detailsCompressorHeaders"
    [detailsRows]="detailsKPIValues"
    [detailsDisplayedHeaders]="detailsCompressorDisplayedHeaders">
  </co2-overview-kpis>

  <co2-performance-drift [performanceDriftGraphInputs]="performanceDriftCompGraph"
    [isLoading]="compressorsCombinedPerformanceDriftLoader$ | async"
    [hasLoadingError]="compressorsCombinedPerformanceDriftError$ | async" (reloadData)="sb.loadCompressorsPerformanceDrift(COMPRESSOR_TYPE, getStartDateSince3Years(), allEquipmentNames, equipmentNamesByType);
                         sb.loadCompressorsHourlyPerformanceDrift(COMPRESSOR_TYPE, allEquipmentNames, equipmentNamesByType)" [displayDatePicker]="true"
                         [startDatePicker]="this.datePickerProperties.startDatePicker"
                         [endDatePicker]="this.datePickerProperties.endDatePicker" (datePick)="filterDatePickDrift($event)">
  </co2-performance-drift>
</div>
