import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import {
  PublicLayoutContainer,
  PrivateLayoutContainer,
  DummyComponent
} from './containers';
import { CancelPendingRequestsGuard, RedirectToLoginOrDashboardGuard } from './guards';
import {AdminAuthGuard} from "./guards/admin-auth/admin-auth.guard";

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutContainer,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'login',
        loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'favorite-asset',
        loadChildren: () => import('./containers/favorite-asset/favorite-asset.module').then(m => m.FavoriteAssetModule)
      },
      {
        path: 'unauthorized',
        loadChildren: () => import('./containers/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule)
      },
      {
        path: 'callback',
        loadChildren: () => import('./containers/callback/callback.module').then(m => m.CallbackModule)
      }
    ]
  },
  {
    path: '',
    component: PrivateLayoutContainer,
    children: [
      {
        path: ':affiliate/:fpso-name/general-dashboard',
        loadChildren: () => import('./containers/general-dashboard/general-dashboard.container.module').then(m => m.GeneralDashboardContainerModule),
        canActivate: [CancelPendingRequestsGuard]
      },
      {
        path: ':affiliate/:fpso-name/settings',
        loadChildren: () => import('./containers/setting-page/setting-page.module').then(m => m.SettingPageModule),
        canActivate: [CancelPendingRequestsGuard]
      },
      {
        path: ':affiliate/:fpso-name/actions',
        loadChildren: () => import('./containers/actions/actions.module').then(m => m.ActionModule),
        canActivate: [CancelPendingRequestsGuard]
      },
      {
        path: ':affiliate/:fpso-name/compressors',
        loadChildren: () => import('./containers/compressors/compressors.container.module').then(m => m.CompressorsContainerModule),
        canActivate: [CancelPendingRequestsGuard]
      },
      {
        path: ':affiliate/:fpso-name/flaring',
        loadChildren: () => import('./containers/flaring/flaring-container.module').then(m => m.FlaringContainerModule),
        canActivate: [CancelPendingRequestsGuard]
      },
      {
        path: ':affiliate/:fpso-name/power-generation/:turbine-tab',
        loadChildren: () => import('./containers/turbogenerators/turbogenerators.container.module').then(m => m.TurbogeneratorsContainerModule),
        canActivate: [CancelPendingRequestsGuard]
      },
      {
        path: ':affiliate/:fpso-name/pumps/:pump_type/:equipment_name',
        loadChildren: () => import('./containers/pump/pump.container.module').then(m => m.PumpContainerModule),
        canActivate: [CancelPendingRequestsGuard]
      },
      {
        path: 'configurations',
        loadChildren: () => import('./containers/configuration/configuration.module').then(m => m.ConfigurationModule),
        canActivate: [CancelPendingRequestsGuard, AdminAuthGuard]
      },
      {
        path: ':affiliate/:fpso-name/config',
        loadChildren: () => import('./containers/admin/admin.module').then(m => m.AdminModule),
        canActivate: [CancelPendingRequestsGuard]
      },
      {
        path: '**',
        component: DummyComponent,
        canActivate: [RedirectToLoginOrDashboardGuard]
      }
    ],
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
