import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { Alert, AlertRules, DataType, TableHeader } from 'src/modules/app/types';

const ALERT_TYPE_WITHOUT_MORE_GRAPHS = [AlertRules.EQUIPMENT_REDUNDANCY];

@Component({
  selector: 'co2-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: [ './side-panel.component.scss' ]
})
export class SidePanelComponent implements OnInit {

  @Input() noAvailableData: boolean = false;
  @Input() selectedAlert!: Alert;
  @Output() closePanel = new EventEmitter();
  @Output() navigateToDetails = new EventEmitter();
  @Output() editMessageEvent = new EventEmitter<string>();
  @Output() updateSelectedAlert = new EventEmitter();
  headers: TableHeader[] = [
    {
      id: 'parameter',
      label: 'Parameter',
      dataType: DataType.TEXT,
    },
    {
      id: 'corresponding_tags',
      label: 'Corresponding Tags',
      dataType: DataType.BADGE,
    },
    {
      id: 'unit',
      label: 'Unit',
      dataType: DataType.TEXT,
    },
    {
      id: 'source',
      label: 'Source',
      dataType: DataType.TEXT,
    } ];

  currentUser: any;
  user: any;
  userName$: Observable<string> = this.sb.userName$;
  equipmentName: string = '';
  affiliate: string = '';
  fpso: string = '';

  constructor(public sb: AppSandbox) {
  }

  ngOnInit(): void {
    this.sb.loadUserName();
    this.user = this.userName$.subscribe((data) => {
      this.currentUser = data;
    });
    [this.affiliate, this.fpso] = [this.sb.getActiveAffiliate(), this.sb.getActiveFpso()];
  }

  isClickInside(event: MouseEvent): boolean {
    const element = document.querySelector('.user__actions__list');
    if (element) {
      return element.contains(event.target as Node);
    }
    return false;
  }

  closeAllActiveEdits(messages: any) {
    for (const message of messages) {
      message.activeEdit = false;
    }
  }

  public updateAlert() {
    this.updateSelectedAlert.emit()
  }

  isViewMoreGraphsActive() {
    return !ALERT_TYPE_WITHOUT_MORE_GRAPHS.includes(this.selectedAlert.alertType);
  }
}
